import { ContentProps, EditorialFactoryProps } from "components/flexComponents/Editorial/typings";
import * as React from "react";
import { Action, FlexTrackingInfo, sendDelayedTrackEvent } from "components/utility/analytics/FlexAnalyticsUtils";
import { EditorialWithLink } from "components/flexComponents/Editorial/factory/impl/editorials/EditorialWithLink";
import EditorialBase from "components/flexComponents/Editorial/factory/EditorialBase";

export class CardWithLinkEditorial extends EditorialBase {
  constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }

  public generate(props: ContentProps) {
    const { analytics } = props;

    if (!this.editorial.items[0].media || this.editorial.items[0].media.length === 0) {
      return null;
    }

    /* istanbul ignore next */
    const cardClicked = () => {
      const { title, clickEventModuleName, clickEventLinkName } = this.editorial;

      const trackingInfo: FlexTrackingInfo = {
        moduleName: clickEventModuleName || "editorial",
        rfrr: (title || "").replace(/\s/g, ""),
        linkName: clickEventLinkName,
        action: Action.CLICK,
      };

      sendDelayedTrackEvent(trackingInfo, analytics);
    };

    const uitkClassNames = this.getEditorialItemClassName();

    return (
      <div
        id={this.id}
        data-fm={this.fmId}
        data-fm-title-id={this.fmTitleId}
        className={`Editorial ${uitkClassNames}`}
        onClick={cardClicked}
        tabIndex={-1}
      >
        <EditorialWithLink model={this.editorial} />
      </div>
    );
  }

  public getEditorialItemClassName = (): string =>
    "uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-medium-blockend-three";
}

export default CardWithLinkEditorial;
