import { Media } from "typings/microserviceModels/content-flex-module";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";

export class EditorialItemModel {
  public readonly id: string;

  public readonly text?: string;

  public readonly title?: string;

  public readonly subtitle?: string;

  public readonly uitkIconName?: string;

  public readonly view?: string;

  public readonly toggle?: string;

  public readonly iconSize?: string;

  public readonly padding?: string;

  public readonly boxType?: string;

  public readonly imageAspectRatio?: string;

  public readonly media?: Media;

  public readonly context?: ExtendedContextStore;

  public readonly contentSource?: string;

  public readonly enableContentSource?: boolean;

  public readonly trackRfrr?: string;

  public readonly index?: number;

  public readonly isAboveTheFold?: boolean;

  public readonly trackRfrridClick?: (
    track: (rfrr: string, linkName: string, delay?: boolean) => void
  ) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;

  /* istanbul ignore next */
  public constructor(
    id: string,
    text?: string,
    title?: string,
    subtitle?: string,
    uitkIconName?: string,
    view?: string,
    toggle?: string,
    iconSize?: string,
    padding?: string,
    boxType?: string,
    media?: Media,
    context?: ExtendedContextStore,
    imageAspectRatio?: string,
    contentSource?: string,
    enableContentSource?: boolean,
    index?: number,
    trackRfrr?: string,
    isAboveTheFold?: boolean,
    trackRfrridClick?: (
      track: (rfrr: string, linkName: string, delay?: boolean) => void
    ) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  ) {
    this.id = id;
    this.text = text;
    this.title = title;
    this.subtitle = subtitle;
    this.uitkIconName = uitkIconName;
    this.view = view;
    this.toggle = toggle;
    this.iconSize = iconSize;
    this.media = media;
    this.boxType = boxType;
    this.context = context;
    this.padding = padding;
    this.imageAspectRatio = imageAspectRatio;
    this.contentSource = contentSource;
    this.enableContentSource = enableContentSource;
    this.index = index;
    this.trackRfrr = trackRfrr;
    this.isAboveTheFold = isAboveTheFold;
    this.trackRfrridClick = trackRfrridClick;
  }
}

export default EditorialItemModel;
