import { EditorialFactoryProps } from "components/flexComponents/Editorial/typings";
import CardWithLinkEditorial from "components/flexComponents/Editorial/factory/impl/editorials/CardWithLinkEditorial";

export class ThemeSelectorCard extends CardWithLinkEditorial {
  /* istanbul ignore next */
  constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }

  public getEditorialItemClassName = (): string => "all-y-gutter-three themeSelectorCard";
}

export default ThemeSelectorCard;
