import * as React from "react";
import { UitkLayoutGrid, UitkLayoutGridItem, UitkLayoutConditionalGridSpan } from "uitk-react-layout-grid";
import { UitkText } from "uitk-react-text";
import { UitkFigure, UitkImage } from "uitk-react-images";
import { UitkCard, UitkCardContentSection } from "uitk-react-cards";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EditorialFlexModule } from "src/typings/microserviceModels/content-flex-module";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { UitkSpacing } from "uitk-react-spacing";

export const CitiRewardsWithoutCompSectionEditorial = (props: {
  model: EditorialFlexModule;
  context: ExtendedContextStore;
}) => {
  const {
    model: { items: textElements },
    context: { deviceInformation },
  } = props;
  const items = (textElements && textElements.filter((textElement) => textElement)) || [];
  const { tablet: isTablet, mobile: isMobile } = deviceInformation || {};
  const imageColSpan: UitkLayoutConditionalGridSpan = { small: 6, medium: 5, large: 5, extra_large: 5 };
  const iconColSpan: UitkLayoutConditionalGridSpan = { small: 2, medium: 3, large: 2, extra_large: 2 };
  const imagegridColumns = {
    extra_large: 12,
    large: 12,
    medium: 12,
    small: 1,
  } as const;
  const textgridColumns = {
    extra_large: 12,
    large: 12,
    medium: 12,
    small: 6,
  } as const;

  if (items.length !== 8) {
    return null;
  }

  const [headingText, subHeadingText, flightIcon, hotelIcon, carIcon, activityIcon, vacationIcon] = items
    .slice(1)
    .map((item) => <DecorateEditorialHtml key={item.id} text={item.text} />);
  const altText = items[0].text;
  const media = items[0].media;
  let mediaUrl = media && media[1].mediaUrl;
  if (isMobile) {
    mediaUrl = media && media[0].mediaUrl;
  } else if (isTablet) {
    mediaUrl = media && media[2].mediaUrl;
  }

  return (
    <UitkSpacing padding={{ inline: "twelve", block: "twelve" }}>
      <UitkCard border>
        <UitkLayoutGrid columns={imagegridColumns}>
          <UitkLayoutGridItem colSpan={imageColSpan}>
            <UitkFigure ratio={UitkFigure.AspectRatio.R16_9}>
              <UitkImage src={mediaUrl} alt={altText} />
            </UitkFigure>
          </UitkLayoutGridItem>

          <UitkLayoutGridItem colSpan={6}>
            <div>
              <UitkCardContentSection>
                <UitkText align="left" size={700} weight="bold">
                  <UitkSpacing
                    padding={{
                      small: { inline: "two", blockstart: "three", blockend: "two" },
                      medium: { inline: "six", blockstart: "twelve" },
                    }}
                  >
                    <div>{headingText}</div>
                  </UitkSpacing>
                </UitkText>

                <UitkText align="left" size={300} weight="regular">
                  <UitkSpacing
                    padding={{ small: { inline: "two" }, medium: { inline: "six" }, large: { blockend: "six" } }}
                  >
                    <div>{subHeadingText}</div>
                  </UitkSpacing>
                </UitkText>
              </UitkCardContentSection>

              <UitkCardContentSection>
                <UitkSpacing
                  padding={{
                    small: { inlinestart: "one", inlineend: "four", block: "six" },
                    medium: { inlinestart: "twelve", blockend: "twelve", blockstart: "six" },
                    large: { blockstart: "unset" },
                  }}
                >
                  <UitkLayoutGrid rows={1} columns={textgridColumns} space="six">
                    <UitkLayoutGridItem colSpan={2}>
                      <div>
                        <UitkText align="center" size={300} weight="regular">
                          <div>{flightIcon}</div>
                        </UitkText>
                      </div>
                    </UitkLayoutGridItem>

                    <UitkLayoutGridItem colSpan={2}>
                      <UitkSpacing padding={{ medium: { inlinestart: "two" } }}>
                        <div>
                          <UitkText align="center" size={300} weight="regular">
                            <div>{hotelIcon}</div>
                          </UitkText>
                        </div>
                      </UitkSpacing>
                    </UitkLayoutGridItem>

                    <UitkLayoutGridItem colSpan={2}>
                      <UitkSpacing padding={{ medium: { inlinestart: "two" } }}>
                        <div>
                          <UitkText align="center" size={300} weight="regular">
                            <div>{carIcon}</div>
                          </UitkText>
                        </div>
                      </UitkSpacing>
                    </UitkLayoutGridItem>

                    <UitkLayoutGridItem colSpan={iconColSpan}>
                      <UitkSpacing padding={{ medium: { inlinestart: "two" } }}>
                        <div>
                          <UitkText align="center" size={300} weight="regular">
                            <div>{activityIcon}</div>
                          </UitkText>
                        </div>
                      </UitkSpacing>
                    </UitkLayoutGridItem>

                    <UitkLayoutGridItem colSpan={iconColSpan}>
                      <UitkSpacing padding={{ medium: { inlinestart: "two" } }}>
                        <div>
                          <UitkText align="center" size={300} weight="regular">
                            <div>{vacationIcon}</div>
                          </UitkText>
                        </div>
                      </UitkSpacing>
                    </UitkLayoutGridItem>
                  </UitkLayoutGrid>
                </UitkSpacing>
              </UitkCardContentSection>
            </div>
          </UitkLayoutGridItem>
        </UitkLayoutGrid>
      </UitkCard>
    </UitkSpacing>
  );
};
export default CitiRewardsWithoutCompSectionEditorial;
