import { ContentProps, EditorialFactoryProps } from "components/flexComponents/Editorial/typings";
import * as React from "react";
import EditorialBase from "components/flexComponents/Editorial/factory/EditorialBase";
import { EditorialModel, Media } from "typings/microserviceModels/content-flex-module";
import { RenderableContentItem } from "src/components/flexComponents/Editorial/factory/impl/items/RenderableContentItem";
import EditorialItemModel from "components/flexComponents/Editorial/factory/impl/items/EditorialItemModel";
import { UitkHeading, UitkSubheading } from "uitk-react-text";
import { UitkSpacing } from "uitk-react-spacing";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { FlexViewModelStore } from "stores/flexViewModel/FlexViewModelStore";
import { ModuleViewType } from "components/flexComponents/Editorial/factory/EditorialContentFactory";

export interface DefaultEditorialProps {
  key?: string;
  id?: string;
  text?: string;
  title?: string;
  subtitle?: string;
  view?: string;
  toggle?: string;
  media?: Media;
  padding?: string;
  boxType?: string;
  context?: ExtendedContextStore;
  imageAspectRatio?: string;
  contentSource?: string;
  enableContentSource?: boolean;
  flexViewModel?: FlexViewModelStore;
  index?: number;
  trackRfrr?: string;
  hasContainerPadding?: boolean;
  isAboveTheFold?: boolean;
  trackRfrridClick?: (
    track: (rfrr: string, linkName: string, delay?: boolean) => void
  ) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const RenderableContentItemContext = React.createContext<Partial<DefaultEditorialProps>>({});

export const useRenderableContext = () => {
  const renderableContext = React.useContext(RenderableContentItemContext);
  if (!renderableContext) throw new Error("RenderableContentItemContext not found");
  return renderableContext;
};

export class DefaultEditorial extends EditorialBase {
  public commonProps: DefaultEditorialProps;

  /* istanbul ignore next */
  public constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }

  /**
   * An Editorial consists of HTML strings that we parse through with the best of
   * our abilities and display as is on the page. Mainly, we do not control the
   * content of an Editorial, we simply put them in the page.
   */
  public generate(props: ContentProps) {
    const queryParams = new URLSearchParams(props.location.search);
    const expandEditorialWithId = queryParams.get("expandEditorial") === this.id;
    const {
      title: moduleTitle,
      subtitle: moduleSubtitle,
      padding,
      boxType,
      items,
      enableContentSource,
      toggle: defaultToggle,
      view,
      imageAspectRatio,
      trackRfrr,
      hasContainerPadding,
      isAboveTheFold,
    } = this.editorial;
    const contentSource = items[0]?.builtFrom;
    const toggle = expandEditorialWithId ? "expanded" : defaultToggle;
    const isCanvasOrCardWithLinkTextView =
      view === ModuleViewType.OnCanvasBanner ||
      view === ModuleViewType.CardWithLinkText ||
      view === ModuleViewType.SingleColumnOnCanvas;
    const classNameAttr = `${this.getEditorialItemClassName()} ${
      isCanvasOrCardWithLinkTextView ? "editorialSpacing" : ""
    }`;

    return (
      <UitkSpacing
        padding={{
          small: { blockend: !hasContainerPadding ? undefined : isCanvasOrCardWithLinkTextView ? "twelve" : undefined },
          medium: { blockend: !hasContainerPadding ? undefined : isCanvasOrCardWithLinkTextView ? "unset" : undefined },
        }}
      >
        <div className={classNameAttr} id={this.id} data-fm={this.fmId} data-fm-title-id={this.fmTitleId}>
          {moduleTitle && (
            <UitkSpacing margin={{ block: "one" }}>
              <UitkHeading tag="h2" size={4}>
                {moduleTitle}
              </UitkHeading>
            </UitkSpacing>
          )}
          {moduleSubtitle && (
            <UitkSpacing margin={{ block: "two" }}>
              <UitkSubheading tag="h3">{moduleSubtitle}</UitkSubheading>
            </UitkSpacing>
          )}
          {this.editorial.items
            .filter((item: EditorialModel) => item.text || item.title)
            .map((item: EditorialModel, index) => {
              const imageToDisplay = item.media?.find((media) => media.mediaType === "Image" && !!media.mediaUrl);

              return this.getItem(
                new EditorialItemModel(
                  item.id,
                  item.text,
                  item.title,
                  item.subtitle,
                  this.editorial.uitkIconName,
                  view,
                  toggle,
                  this.editorial.floatImage,
                  padding,
                  boxType,
                  imageToDisplay,
                  props.context,
                  imageAspectRatio,
                  contentSource,
                  enableContentSource,
                  index,
                  trackRfrr,
                  isAboveTheFold,
                  this.trackRfrridClick
                )
              );
            })}
        </div>
      </UitkSpacing>
    );
  }

  public generateCommonProps = (model: EditorialItemModel) => ({
    id: model.id,
    text: model.text,
    title: model.title,
    subtitle: model.subtitle,
    toggle: model.toggle,
    view: model.view || "",
    key: `${this.contentPurpose}-${this.keyHelper.next()}`,
    padding: model.padding,
    boxType: model.boxType,
    media: model.media,
    context: model.context,
    imageAspectRatio: model.imageAspectRatio,
    contentSource: model.contentSource,
    enableContentSource: model.enableContentSource,
    index: model.index,
    trackRfrr: model.trackRfrr,
    isAboveTheFold: model.isAboveTheFold,
    trackRfrridClick: model.trackRfrridClick,
  });

  public getItem(model: EditorialItemModel): JSX.Element {
    this.commonProps = this.generateCommonProps(model);

    return (
      <RenderableContentItemContext.Provider value={this.commonProps}>
        <RenderableContentItem />
      </RenderableContentItemContext.Provider>
    );
  }
}

export default DefaultEditorial;
