import * as React from "react";
import { CitiHeaderSection } from "./CitiHeaderSection";
import { CitiTripleDipIconSectionWithAdd } from "./CitiTripleDipIconSectionWithAdd";
import { CitiTripleDipIconSectionWithoutAdd } from "./CitiTripleDipIconSectionWithoutAdd";
import CitifaqHeader from "./CitifaqHeader";
import { EditorialFlexModule } from "src/typings/microserviceModels/content-flex-module";

enum TitleTexts {
  TRIPLE_DIP_HEADING = "triple_dip_section_Heading",
  TRIPLE_DIP_ICON_SEC_ONE = "triple_dip_section_Icon_Section1",
  TRIPLE_DIP_ICON_SEC_TWO = "triple_dip_section_Icon_Section2",
  TRIPLE_DIP_ICON_SEC_THREE = "triple_dip_section_Icon_Section3",
  TRIPLE_DIP_ICON_SEC_SEPARATOR = "triple_dip_section_separator",
}

export const CitiTripleDipSectionEditorial = (props: EditorialFlexModule) => {
  const { title, items } = props;

  return (
    <>
      {title === TitleTexts.TRIPLE_DIP_HEADING && <CitiHeaderSection items={items} />}

      {title === TitleTexts.TRIPLE_DIP_ICON_SEC_ONE && <CitiTripleDipIconSectionWithAdd items={items} />}

      {title === TitleTexts.TRIPLE_DIP_ICON_SEC_TWO && <CitiTripleDipIconSectionWithAdd items={items} />}

      {title === TitleTexts.TRIPLE_DIP_ICON_SEC_THREE && <CitiTripleDipIconSectionWithoutAdd items={items} />}

      {title === TitleTexts.TRIPLE_DIP_ICON_SEC_SEPARATOR && <CitifaqHeader items={items} />}
    </>
  );
};

export default CitiTripleDipSectionEditorial;
