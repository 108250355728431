import * as React from "react";
import { UitkLink } from "uitk-react-link";
import { UitkImage } from "uitk-react-images";
import { ImageMediaType } from "src/components/flexComponents/Editorial/typings";

export const ImageEditorialWithLink = ({ mediaUrl, mediaAlt, clickUrl }: ImageMediaType) => (
  <UitkLink>
    <a href={clickUrl}>
      <UitkImage
        key={mediaUrl}
        alt={mediaAlt}
        src={mediaUrl}
        placeholderImage
        className="images-size"
        lazyLoading="lazy"
      />
    </a>
  </UitkLink>
);

export default ImageEditorialWithLink;
