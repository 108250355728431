import * as React from "react";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { UitkText } from "uitk-react-text";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "uitk-react-layout-flex";
import { UitkLayoutGrid, UitkLayoutGridItem } from "uitk-react-layout-grid";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { UitkSpacing } from "uitk-react-spacing";

const CONTENT_ID_COUNT = 4;
export const CardComparisonAddedBenefitsEditorial = (props: { items: EditorialModel[] }) => {
  const { items } = props;

  if (items.length !== CONTENT_ID_COUNT) {
    return null;
  }

  const [
    feeCardAddedBenefitsIcon,
    feeCardAddedBenefitsOne,
    feeCardAddedBenefitsTwo,
    feeCardAddedBenefitsThree,
  ] = React.useMemo(() => items.map((item) => <DecorateEditorialHtml key={item.id} text={item.text} />), []);

  return (
    <UitkSpacing
      padding={{ medium: { inline: "twelve" }, large: { inline: "unset", inlinestart: "twelve" } }}
      margin={{ medium: { inline: "twelve" }, large: { inline: "unset", inlinestart: "six" } }}
    >
      <div>
        <UitkLayoutGrid>
          <UitkSpacing padding={{ blockstart: "three" }}>
            <UitkLayoutGridItem>
              <UitkLayoutFlex space="three" alignItems="center">
                <UitkLayoutFlexItem>
                  <div>{feeCardAddedBenefitsIcon}</div>
                </UitkLayoutFlexItem>
                <UitkLayoutFlexItem>
                  <div>
                    <UitkText size={300} weight="medium" align="left">
                      <div>{feeCardAddedBenefitsOne}</div>
                    </UitkText>
                  </div>
                </UitkLayoutFlexItem>
              </UitkLayoutFlex>
            </UitkLayoutGridItem>
          </UitkSpacing>
          <UitkSpacing padding={{ blockstart: "three" }}>
            <UitkLayoutGridItem>
              <UitkLayoutFlex space="three" alignItems="center">
                <UitkLayoutFlexItem>
                  <div>{feeCardAddedBenefitsIcon}</div>
                </UitkLayoutFlexItem>
                <UitkLayoutFlexItem>
                  <div>
                    <UitkText size={300} weight="medium" align="left">
                      <div>{feeCardAddedBenefitsTwo}</div>
                    </UitkText>
                  </div>
                </UitkLayoutFlexItem>
              </UitkLayoutFlex>
            </UitkLayoutGridItem>
          </UitkSpacing>
          <UitkSpacing padding={{ blockstart: "three", blockend: "six" }}>
            <UitkLayoutGridItem>
              <UitkLayoutFlex space="three" alignItems="center">
                <UitkLayoutFlexItem>
                  <div>{feeCardAddedBenefitsIcon}</div>
                </UitkLayoutFlexItem>
                <UitkLayoutFlexItem>
                  <div>
                    <UitkText size={300} weight="medium" align="left">
                      <div>{feeCardAddedBenefitsThree}</div>
                    </UitkText>
                  </div>
                </UitkLayoutFlexItem>
              </UitkLayoutFlex>
            </UitkLayoutGridItem>
          </UitkSpacing>
        </UitkLayoutGrid>
      </div>
    </UitkSpacing>
  );
};

export default CardComparisonAddedBenefitsEditorial;
