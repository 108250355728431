import * as React from "react";
import {
  UitkLayoutGrid,
  UitkLayoutGridItem,
  UitkLayoutGridColumnRow,
  UitkLayoutConditionalGridSpan,
} from "uitk-react-layout-grid";
import { UitkText } from "uitk-react-text";
import { UitkCard } from "uitk-react-cards";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "uitk-react-layout-flex";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { UitkSpacing } from "uitk-react-spacing";

export const CitiCalculatorContainer = (props: { items: EditorialModel[] }) => {
  const { items } = props;
  const containerColSpan: UitkLayoutConditionalGridSpan = { small: 2, medium: 3, large: 3, extra_large: 3 };
  const hotelColSpan: UitkLayoutConditionalGridSpan = { small: 1, medium: 3, large: 3, extra_large: 3 };
  const compareColSpan: UitkLayoutConditionalGridSpan = { small: 1, medium: 1, large: 2, extra_large: 2 };
  const costColSpan: UitkLayoutConditionalGridSpan = { small: 3, medium: 4, large: 3, extra_large: 3 };
  const containerGridColumns = {
    extra_large: 12 as UitkLayoutGridColumnRow,
    large: 12 as UitkLayoutGridColumnRow,
    medium: 12 as UitkLayoutGridColumnRow,
    small: 8 as UitkLayoutGridColumnRow,
  };

  if (items.length !== 6) {
    return null;
  }

  const [flightText, hotelText, totalCostText, totalCostAmount, add, compare] = items.map((item) => (
    <DecorateEditorialHtml key={item.id} text={item.text} />
  ));

  return (
    <UitkSpacing padding={{ blockend: "six" }}>
      <UitkLayoutFlex space="two" justifyContent="center">
        <UitkLayoutFlexItem grow={1} minWidth="twenty_four" maxWidth="one_hundred_twenty">
          <UitkCard border>
            <UitkSpacing padding={{ inlineend: "six" }}>
              <UitkLayoutGrid columns={containerGridColumns} space="six" alignContent="center">
                <UitkLayoutGridItem colSpan={containerColSpan}>
                  <UitkSpacing padding={{ blockstart: "six", inlinestart: "six" }}>
                    <UitkLayoutGrid columns={1}>
                      <UitkText align="center" size={300}>
                        <div>{flightText}</div>
                      </UitkText>
                    </UitkLayoutGrid>
                  </UitkSpacing>
                </UitkLayoutGridItem>

                <UitkLayoutGridItem colSpan={1}>
                  <UitkSpacing padding={{ blockstart: "six", inlinestart: "two" }}>
                    <UitkText align="center" size={800}>
                      <div>{add}</div>
                    </UitkText>
                  </UitkSpacing>
                </UitkLayoutGridItem>

                <UitkLayoutGridItem colSpan={hotelColSpan}>
                  <UitkSpacing
                    padding={{
                      blockstart: "six",
                      medium: { inlinestart: "six" },
                      small: { inlineend: "two" },
                    }}
                  >
                    <UitkLayoutGrid columns={1}>
                      <UitkText align="center" size={300}>
                        <div>{hotelText}</div>
                      </UitkText>
                    </UitkLayoutGrid>
                  </UitkSpacing>
                </UitkLayoutGridItem>

                <UitkLayoutGridItem colSpan={compareColSpan}>
                  <UitkSpacing padding={{ block: "six", small: { inlinestart: "two" } }}>
                    <UitkText align="center" size={800}>
                      <div>{compare}</div>
                    </UitkText>
                  </UitkSpacing>
                </UitkLayoutGridItem>

                <UitkLayoutGridItem colSpan={costColSpan}>
                  <UitkSpacing padding={{ block: "six" }}>
                    <UitkLayoutGrid columns={1}>
                      <UitkText align="center" size={300}>
                        <div>{totalCostText}</div>
                      </UitkText>
                      <UitkText align="center" size={600} weight="bold">
                        <div>{totalCostAmount}</div>
                      </UitkText>
                    </UitkLayoutGrid>
                  </UitkSpacing>
                </UitkLayoutGridItem>
              </UitkLayoutGrid>
            </UitkSpacing>
          </UitkCard>
        </UitkLayoutFlexItem>
      </UitkLayoutFlex>
    </UitkSpacing>
  );
};
export default CitiCalculatorContainer;
