import * as React from "react";
import LazyLoad from "react-lazyload";
import EditorialBase from "components/flexComponents/Editorial/factory/EditorialBase";
import { UitkCard, UitkCardPlaceholder } from "uitk-react-cards";
import { UitkText, UitkHeading, UitkSubheading } from "uitk-react-text";
import { UitkSpacing } from "uitk-react-spacing";
import { UitkLayoutGrid, UitkLayoutGridItem } from "uitk-react-layout-grid";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "uitk-react-layout-flex";
import { EditorialFactoryProps, ContentProps } from "components/flexComponents/Editorial/typings";
import { Media } from "src/typings/microserviceModels/content-flex-module";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { UitkFigure, UitkFigureAspectRatioType } from "uitk-react-images";
import { UitkScrimType, UitkScrim } from "uitk-react-scrim";
import { UitkAvatar } from "uitk-react-avatar";
import { BlossomImage } from "src/components/shared/BlossomImage/BlossomImage";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";

const ImageDisplay = ({ image, rfrrid }: { image: Media; rfrrid?: string }) => {
  return (
    <LazyLoad offset={500} height="100%" once placeholder={<UitkCardPlaceholder />}>
      <UitkCard>
        <UitkFigure ratio={UitkFigureAspectRatioType.R1_1}>
          <BlossomImage alt={image.mediaAlt} src={image.mediaUrl} imageRatio="1-1" />
          {image.clickUrl && (
            <TrackedLink
              className="uitk-card-link"
              rfrr={rfrrid}
              href={image.clickUrl}
              target="_blank"
              rel="noopener noreferrer"
              moduleName="SocialMediaGridEditorial"
            />
          )}
          {image.mediaCaption && (
            <UitkScrim type={UitkScrimType.BOTTOM}>
              <UitkText>{image.mediaCaption}</UitkText>
            </UitkScrim>
          )}
        </UitkFigure>
      </UitkCard>
    </LazyLoad>
  );
};

export class SocialMediaGridEditorial extends EditorialBase {
  public constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }

  /**
   * An Editorial consists of HTML strings that we parse through with the best of
   * our abilities and display as is on the page. Mainly, we do not control the
   * content of an Editorial, we simply put them in the page.
   */

  public generate(props: ContentProps) {
    const { title: moduleTitle } = this.editorial;
    const { media, title, subtitle, text } = this.editorial.items[0];

    const socialMediaImages = media?.filter((image) => {
      return image.imageType === "General Image";
    });

    const socialMediaAvatar = media?.filter((image) => {
      return image.imageType === "Logo";
    })[0];

    if (!media || media.length === 0) {
      return null;
    }

    return (
      <UitkSpacing padding={{ block: "three" }}>
        <div id={this.id} data-fm={this.fmId} data-fm-title-id={this.fmTitleId} className="SocialMediaGridEditorial">
          <>
            {moduleTitle && (
              <UitkSpacing margin={{ block: "two", inline: "three" }}>
                <div>
                  <UitkHeading tag="h2" size={5}>
                    {moduleTitle}
                  </UitkHeading>
                </div>
              </UitkSpacing>
            )}
            <UitkLayoutFlex alignContent="start" alignItems="center">
              <>
                {socialMediaAvatar && (
                  <UitkLayoutFlexItem maxWidth="full_width">
                    <a href={socialMediaAvatar.clickUrl} target="_blank" rel="noopener noreferrer">
                      <UitkAvatar
                        image={{ alt: socialMediaAvatar.mediaAlt, src: socialMediaAvatar.mediaUrl }}
                        border
                        size="large"
                      />
                    </a>
                  </UitkLayoutFlexItem>
                )}
                {title && (
                  <UitkLayoutFlexItem>
                    <UitkSpacing margin={{ inline: "one" }} padding={{ block: "two" }}>
                      <div>
                        <UitkHeading tag="h3" size={5}>
                          {title}
                        </UitkHeading>
                        {subtitle && <UitkSubheading>{subtitle}</UitkSubheading>}
                      </div>
                    </UitkSpacing>
                  </UitkLayoutFlexItem>
                )}
              </>
            </UitkLayoutFlex>
          </>
          {text && (
            <UitkSpacing margin={{ blockstart: "two", blockend: "two" }}>
              <UitkText size={300}>
                <div onClick={this.trackRfrridClick(useClickTracker())}>
                  <DecorateEditorialHtml text={text} />
                </div>
              </UitkText>
            </UitkSpacing>
          )}

          <UitkLayoutGrid columns={{ small: 3, medium: 6 }} justifyContent="space-around" space="two">
            {socialMediaImages &&
              socialMediaImages.length >= 6 &&
              socialMediaImages.length < 12 &&
              socialMediaImages.slice(0, 6).map((item, index) => {
                const rfrrid = `${this.pageName}.${this.moduleName}.${this.linkName}${index + 1}.${this.sectionName}`;
                return (
                  <UitkLayoutGridItem key={this.keyHelper.next()}>
                    <ImageDisplay image={item} rfrrid={rfrrid} />;
                  </UitkLayoutGridItem>
                );
              })}
            {socialMediaImages &&
              socialMediaImages.length >= 12 &&
              media.slice(0, 12).map((item, index) => {
                const rfrrid = `${this.pageName}.${this.moduleName}.${this.linkName}${index + 1}.${this.sectionName}`;
                return (
                  <UitkLayoutGridItem key={this.keyHelper.next()}>
                    <ImageDisplay image={item} rfrrid={rfrrid} />;
                  </UitkLayoutGridItem>
                );
              })}
          </UitkLayoutGrid>
        </div>
      </UitkSpacing>
    );
  }
}

export default SocialMediaGridEditorial;
