import * as React from "react";
import { UitkCard, UitkCardContentSection } from "uitk-react-cards";
import { UitkText } from "uitk-react-text";
import { UitkIcon, UitkIconSize } from "uitk-react-icons";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "uitk-react-layout-flex";
import { UitkSpacing } from "uitk-react-spacing";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";

export const CitifaqSection = (props: { items: EditorialModel[] }) => {
  const { items } = props;
  const [isVisible, setIsVisible] = React.useState(false);
  const toggle = React.useCallback(() => setIsVisible(!isVisible), [isVisible]);
  const expandoKeyboardPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" || e.key === " ") {
      toggle();
    }
  };

  if (items.length !== 2) {
    return null;
  }

  return (
    <UitkLayoutFlex space="two" wrap="wrap" justifyContent="center">
      <UitkLayoutFlexItem grow={1} minWidth="twenty_four" maxWidth="one_hundred_twenty">
        <UitkSpacing padding={{ blockend: "three" }}>
          <div>
            <UitkCard>
              <div
                className="CallToAction"
                tabIndex={0}
                role="button"
                aria-expanded={isVisible}
                onClick={toggle}
                data-testid="expando-toggle"
                onKeyDown={expandoKeyboardPress}
              >
                <UitkCardContentSection padded>
                  <UitkLayoutFlex>
                    <UitkLayoutFlexItem grow={1}>
                      <UitkText size={400} weight="bold" theme="default">
                        <UitkSpacing margin={{ inlineend: "twelve" }}>
                          <DecorateEditorialHtml text={items[0].text} />
                        </UitkSpacing>
                      </UitkText>
                    </UitkLayoutFlexItem>
                    <UitkLayoutFlexItem alignSelf="center" grow={0}>
                      <UitkIcon name={isVisible ? "expand_less" : "expand_more"} size={UitkIconSize.SMALL} />
                    </UitkLayoutFlexItem>
                  </UitkLayoutFlex>
                </UitkCardContentSection>
              </div>
              <UitkCardContentSection
                padded={["blockend", "inlinestart", "inlineend"]}
                className={`${isVisible ? "" : "is-hidden"}`}
              >
                <UitkSpacing padding={{ blockend: "one", inline: "one" }}>
                  <div>
                    <UitkText size={200}>
                      <DecorateEditorialHtml text={items[1].text} />
                    </UitkText>
                  </div>
                </UitkSpacing>
              </UitkCardContentSection>
            </UitkCard>
          </div>
        </UitkSpacing>
      </UitkLayoutFlexItem>
    </UitkLayoutFlex>
  );
};

export default CitifaqSection;
