import * as React from "react";
import { EditorialFlexModule } from "src/typings/microserviceModels/content-flex-module";
import CitifaqHeader from "./CitifaqHeader";
import CitifaqSection from "./CitifaqSection";

export const CitifaqSectionEditorial = (props: EditorialFlexModule) => {
  const { title, items } = props;

  return (
    <>
      {title === "Citi_Faq_Section_Heading" && <CitifaqHeader items={items} />}

      {title.includes("Citi_Faq_Section") && <CitifaqSection items={items} />}
    </>
  );
};
export default CitifaqSectionEditorial;
