import { ContentComponent } from "components/flexComponents/Editorial/factory/ContentComponent";
import { EditorialFlexModule } from "typings/microserviceModels/content-flex-module";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { RfrrTrackingEventType, EditorialFactoryProps } from "components/flexComponents/Editorial/typings";
import {
  FlexTrackingInfo,
  sendImmediateClickstreamTrackEvent,
  Action,
} from "src/components/utility/analytics/FlexAnalyticsUtils";
import { ModuleViewType } from "./EditorialContentFactory";

/**
 * Abstract extension of ContentComponent that holds editorial specific fields and implements some of the functions that behave the same for most editorials.
 */
export abstract class EditorialBase extends ContentComponent {
  public readonly editorial: EditorialFlexModule;

  public readonly contentPurpose?: string;
  public moduleName?: string;
  public linkName?: string;
  public sectionName?: string;
  public pageName?: string;

  protected constructor(editorial: EditorialFactoryProps) {
    super(editorial);
    const { model } = editorial;
    this.editorial = model;
    this.contentPurpose = model.contentPurpose;
    this.keyHelper = new ItemKeyHelper("editorial");
    this.moduleName = this.id;
    this.linkName = "textlink";
    this.sectionName = "content";
    this.pageName = this.flexContentComponent.compositionStore.composition?.templateName;
  }

  public shouldRender = (): boolean =>
    (this.editorial && this.editorial.items.length > 0 && !this.editorial.empty) || false;

  public getId = (): string => this.id;

  public getRfrrid = (): string => `${this.pageName}.${this.moduleName}.${this.linkName}.${this.sectionName}`;

  public getEditorialItemClassName = (): string => {
    const { view } = this.editorial;
    const baseClass = "Editorial";

    if (
      view === ModuleViewType.OnCanvasBanner ||
      view === ModuleViewType.CardWithLinkText ||
      view === ModuleViewType.SingleColumnOnCanvas
    )
      return baseClass;

    return `${baseClass} uitk-spacing uitk-spacing-padding-block-six`;
  };

  public getLinkHref = (target: HTMLElement): string | null => {
    if (target.nodeName === "A") {
      return target.getAttribute("href");
    } else if (target.parentNode?.nodeName === "A") {
      const parentNode = target.parentNode as HTMLElement;
      return parentNode.getAttribute("href");
    }
    return null;
  };

  public sendTrack = (trackingProps: RfrrTrackingEventType) => {
    const { track, rfrrid, moduleName } = trackingProps;
    const flexTracking: FlexTrackingInfo = {
      moduleName,
      rfrr: rfrrid,
      action: Action.CLICK,
    };

    sendImmediateClickstreamTrackEvent(flexTracking, track);
  };

  public getLinkRfrrid = (target: HTMLElement): string => {
    const linkHref = this.getLinkHref(target);
    if (linkHref) {
      const { linkName, sectionName } = this.getLinkSectionName(linkHref);
      return `${this.pageName}.${this.moduleName}.${linkName}.${sectionName}`;
    }
    return this.getRfrrid();
  };

  public getLinkSectionName = (href: string): { linkName: string; sectionName: string } => {
    const linkName = this.getLinkName(href);
    const sectionName = linkName !== "textlink" ? "social" : "content";
    return { linkName: linkName, sectionName: sectionName };
  };

  /* istanbul ignore next */
  public getLinkName = (href: string): string => {
    if (href.match(/^https:\/\/.*facebook\.com$/)) {
      return "fb";
    }
    if (href.match(/^https:\/\/.*twitter\.com$/)) {
      return "tw";
    }
    if (href.match(/^https:\/\/.*plus\.google\.com$/)) {
      return "gp";
    }
    if (href.match(/^https:\/\/.*youtube\.com$/)) {
      return "yt";
    }
    if (href.match(/^https:\/\/.*instagram\.com$/)) {
      return "inst";
    }
    if (href.match(/^https:\/\/.*pinterest\.com$/)) {
      return "pin";
    }
    return "textlink";
  };

  public trackRfrridClick = (track: (rfrr: string, linkName: string, delay?: boolean) => void) => {
    return (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const target = event.target as HTMLElement;
      const rfrrid = this.getLinkRfrrid(target);
      if (rfrrid) {
        const rfrrTrackingProps: RfrrTrackingEventType = {
          track,
          rfrrid: rfrrid || "",
          moduleName: this.moduleName || "",
        };
        this.sendTrack(rfrrTrackingProps);
      }
    };
  };
}

export default EditorialBase;
