import * as React from "react";
import EditorialBase from "components/flexComponents/Editorial/factory/EditorialBase";
import { EditorialModel, Media } from "typings/microserviceModels/content-flex-module";
import { EditorialFactoryProps } from "components/flexComponents/Editorial/typings";
import { BlossomImage } from "src/components/shared/BlossomImage/BlossomImage";

export class ImageEditorial extends EditorialBase {
  /* istanbul ignore next */
  public constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }

  /**
   * An Editorial consists of HTML strings that we parse through with the best of
   * our abilities and display as is on the page. Mainly, we do not control the
   * content of an Editorial, we simply put them in the page.
   */
  public generate() {
    return (
      <div id={this.id} data-fm={this.fmId} data-fm-title-id={this.fmTitleId}>
        {this.editorial.items
          .filter((item: EditorialModel) => item.media && item.media.filter((media) => media.mediaUrl))
          .map((item: EditorialModel) => item.media)
          .reduce((item, acum) => acum && acum.concat.apply(acum, item), [])
          .map((media: Media) => {
            return (
              <BlossomImage
                src={media.mediaUrl}
                alt={media.mediaAlt}
                imageRatio={this.editorial.imageAspectRatio}
                key={media.mediaUrl}
                quality={this.editorial.quality}
                widthSmall={this.editorial.minWidthSmall}
                widthMedium={this.editorial.minWidthMedium}
                widthLarge={this.editorial.minWidthLarge}
              />
            );
          })}
      </div>
    );
  }
}

export default ImageEditorial;
