import { EditorialFactoryProps } from "components/flexComponents/Editorial/typings";
import * as React from "react";
import EditorialBase from "components/flexComponents/Editorial/factory/EditorialBase";
import { EditorialModel, Media } from "typings/microserviceModels/content-flex-module";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "uitk-react-layout-flex";
import { ImageEditorialWithLink } from "./ImageEditorialWithLink";
import { UitkImage } from "uitk-react-images";
import { UitkSpacing } from "uitk-react-spacing";
import { UitkText } from "uitk-react-text";

export class MultipleImages extends EditorialBase {
  /* istanbul ignore next */
  public constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }

  private generateImageComponents = (items: EditorialModel[]) =>
    items
      .filter((item: EditorialModel) => item.media && item.media.filter((media) => media.mediaUrl))
      .map((item: EditorialModel) => item.media)
      .reduce((item, acum) => acum && acum.concat.apply(acum, item), [])
      .sort((a: Media, b: Media) => a.mediaSeq - b.mediaSeq)
      .map(({ mediaUrl, mediaAlt, clickUrl }: Media) => (
        <UitkLayoutFlexItem key={mediaUrl}>
          <div>
            {clickUrl ? (
              <ImageEditorialWithLink key={mediaUrl} mediaUrl={mediaUrl} mediaAlt={mediaAlt} clickUrl={clickUrl} />
            ) : (
              <UitkSpacing padding={{ block: "two" }}>
                <div>
                  <UitkImage key={mediaUrl} alt={mediaAlt} src={mediaUrl} className="images-size" />
                </div>
              </UitkSpacing>
            )}
          </div>
        </UitkLayoutFlexItem>
      ));

  /**
   * An Editorial consists of HTML strings that we parse through with the best of
   * our abilities and display as is on the page. Mainly, we do not control the
   * content of an Editorial, we simply put them in the page.
   */
  public generate() {
    return (
      <UitkSpacing padding={{ blockstart: "six" }}>
        <UitkLayoutFlex
          id={this.id}
          data-fm={this.fmId}
          data-fm-title-id={this.fmTitleId}
          direction="column"
          className="editorial-images-flex-container"
        >
          <UitkLayoutFlexItem>
            <UitkText align="center" size={200}>
              {this.editorial.items.map((item) => item.title)}
            </UitkText>
          </UitkLayoutFlexItem>
          <UitkLayoutFlex justifyContent="center">
            <UitkLayoutFlexItem>
              <div>
                <UitkLayoutFlex justifyContent="center" space="twelve">
                  {this.generateImageComponents(this.editorial.items)}
                </UitkLayoutFlex>
              </div>
            </UitkLayoutFlexItem>
          </UitkLayoutFlex>
        </UitkLayoutFlex>
      </UitkSpacing>
    );
  }
}

export default MultipleImages;
