import { EditorialFactoryProps } from "components/flexComponents/Editorial/typings";
import * as React from "react";
import { ConfidenceEditorialItem } from "components/flexComponents/Editorial/factory/impl/items/ConfidenceEditorialItem";
import DefaultEditorial from "components/flexComponents/Editorial/factory/impl/editorials/DefaultEditorial";
import EditorialItemModel from "components/flexComponents/Editorial/factory/impl/items/EditorialItemModel";

export class ConfidenceEditorial extends DefaultEditorial {
  /* istanbul ignore next */
  constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }

  public getItem(model: EditorialItemModel): JSX.Element {
    return (
      <ConfidenceEditorialItem
        id={model.id}
        text={model.text}
        uitkIconName={model.uitkIconName}
        iconSize={model.iconSize}
        boxType={model.boxType}
        key={`${this.contentPurpose}-${this.keyHelper.next()}`}
      />
    );
  }

  public getEditorialItemClassName = (): string =>
    "Editorial confidenceMessage uitk-spacing uitk-spacing-padding-block-three";
}

export default ConfidenceEditorial;
