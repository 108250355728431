import { EditorialFactoryProps } from "components/flexComponents/Editorial/typings";
import * as React from "react";
import { Viewport, ViewSmall, ViewMedium, ViewLarge } from "@shared-ui/viewport-context";
import { Action } from "components/utility/analytics/FlexAnalyticsUtils";
import EditorialBase from "components/flexComponents/Editorial/factory/EditorialBase";

import { UitkCard, UitkCardLink } from "uitk-react-cards";
import { UitkFigure, UitkFigureAspectRatioType, UitkImage } from "uitk-react-images";
import { UitkScrim, UitkScrimType } from "uitk-react-scrim";
import { UitkSpacing } from "uitk-react-spacing";
import { EditorialFlexModule } from "src/typings/microserviceModels/content-flex-module";

export class NativeTileShowcaseEditorial extends EditorialBase {
  constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }

  public generate() {
    const {
      editorial: { items },
      id,
      fmId,
      fmTitleId,
    } = this;
    if (!items[0].media || items[0].media.length === 0) {
      return null;
    }

    const [{ media }] = items;
    const [desktopMedia, mobileMedia] = media;

    const desktopMediaURL = desktopMedia ? desktopMedia.mediaUrl : "";
    const mobileMediaURL = mobileMedia ? mobileMedia.mediaUrl : "";
    const layoutProps = {
      id,
      fmId,
      fmTitleId,
      model: this.editorial,
    };

    return (
      <Viewport>
        <ViewSmall>
          <EditorialLayout {...layoutProps} imageURL={mobileMediaURL} />
        </ViewSmall>
        <ViewMedium>
          <EditorialLayout {...layoutProps} imageURL={desktopMediaURL} />
        </ViewMedium>
        <ViewLarge>
          <EditorialLayout {...layoutProps} imageURL={desktopMediaURL} />
        </ViewLarge>
      </Viewport>
    );
  }
}

const EditorialLayout = (props: {
  model: EditorialFlexModule;
  imageURL: string;
  id: string;
  fmId: string;
  fmTitleId?: number;
}) => {
  const { model, id, fmId, fmTitleId, imageURL } = props;
  const { items = [], title: moduleTitle, cardLinkUrl } = model;
  if (!items[0].media || items[0].media.length === 0) {
    return null;
  }
  const moduleName = "editorial";
  const cardLabel = items[0].title ? items[0].title : moduleTitle;
  const cardBody = items[0].text ? items[0].text : "";

  const cardName = cardLabel && cardLabel.replace(" ", "_");
  const rfrr = cardName ? `${moduleName}.${cardName}.${Action.CLICK}` : null;

  const dynamicClickUrl = items[0].media[0].clickUrl;

  const url = dynamicClickUrl && dynamicClickUrl.length > 0 ? dynamicClickUrl : cardLinkUrl;
  const cardLink = rfrr ? `${url}/?rfrr=${rfrr}` : url;
  const cardLinkTarget = model.openLinkInNewWindow ? "_blank" : "_self";
  const rel = model.openLinkInNewWindow ? "noopener noreferrer" : undefined;

  const editorialViewClass = "ntsFlex";
  const editorialRatioClass = "ntsFlexRatio";

  /**
   * This is a temporary work around due to limitations of the editorial model that will be addressed in the future.
   * Parsing data from contributed HTML content should not be considered an accepted approach to be repeated elsewhere
   */

  const [, title] = /<span [^>]+>(.*?)<\/span>/g.exec(cardBody) || [];

  return (
    <UitkSpacing margin={{ blockstart: "three" }}>
      <div id={id} data-fm={fmId} data-fm-title-id={fmTitleId} className="Editorial">
        <UitkCard className={editorialViewClass}>
          <UitkFigure className={editorialRatioClass} ratio={UitkFigureAspectRatioType.R4_1}>
            <UitkImage src={imageURL} alt="" placeholderImage lazyLoading="lazy" />
            <UitkScrim type={UitkScrimType.BOTTOM}>
              <div className="nts-scrim-text" dangerouslySetInnerHTML={{ __html: cardBody }} />
            </UitkScrim>
          </UitkFigure>
          <UitkCardLink>
            <a href={cardLink} data-testid="editorial-with-link-anchor" rel={rel} target={cardLinkTarget}>
              {title || moduleTitle || moduleName}
            </a>
          </UitkCardLink>
        </UitkCard>
      </div>
    </UitkSpacing>
  );
};

export default NativeTileShowcaseEditorial;
