import { EditorialFactoryProps } from "components/flexComponents/Editorial/typings";
import ConfidenceEditorial from "components/flexComponents/Editorial/factory/impl/editorials/ConfidenceEditorial";
import InfoViewEditorial from "components/flexComponents/Editorial/factory/impl/editorials/InfoViewEditorial";
import CardWithLinkEditorial from "components/flexComponents/Editorial/factory/impl/editorials/CardWithLinkEditorial";
import CardWithLinkTextEditorial from "components/flexComponents/Editorial/factory/impl/editorials/CardWithLinkTextEditorial";
import SnippetEditorial from "components/flexComponents/Editorial/factory/impl/editorials/SnippetEditorial";
import { NullContentComponent } from "components/flexComponents/Editorial/factory/NullContentComponent";
import { ContentComponent } from "components/flexComponents/Editorial/factory/ContentComponent";
import { ImageEditorial } from "components/flexComponents/Editorial/factory/impl/editorials/ImageEditorial";
import { MultipleImages } from "components/flexComponents/Editorial/factory/impl/editorials/MultipleImages";
import { EditorialViewFactory } from "./EditorialViewFactory";
import AffinityCard from "./impl/editorials/AffinityCard";
import ThemeSelectorCard from "./impl/editorials/ThemeSelector";
import Grid3 from "./impl/editorials/Grid3";
import NativeTileShowcaseEditorial from "./impl/editorials/NativeTileShowcaseEditorial";
import { HalfMediaEditorial } from "./impl/editorials/HalfMediaEditorial";
import { SocialMediaGridEditorial } from "./impl/editorials/SocialMediaGridEditorial";
import { StandaloneMediaEditorial } from "./impl/editorials/StandaloneMediaEditorial";
import { DestinationDescriptionEditorial } from "components/flexComponents/Editorial/factory/impl/editorials/DestinationDescriptionEditorial";
import FullBleedVerticalCard from "./impl/editorials/FullBleedVerticalCard";
import { CarouselEditorial } from "./impl/editorials/CarouselEditorial";
import { ConfidenceMessageImageEditorial } from "./impl/editorials/ConfidenceMessageImageEditorial";
import { Grid4 } from "./impl/editorials/Grid4";

export class EditorialContentFactory {
  public static get = (props: EditorialFactoryProps): ContentComponent => {
    const mainPurpose = getMainPurpose(props.model.contentPurpose);

    if (!mainPurpose) {
      return new NullContentComponent(props);
    }

    let component;

    switch (mainPurpose) {
      case EditorialContentPurpose.HIGHLIGHTS:
      case EditorialContentPurpose.CONFIDENCE_MESSAGE:
        component = new ConfidenceEditorial(props);
        break;
      case EditorialContentPurpose.LOB_CROSSLINKING:
      case EditorialContentPurpose.EDITORIAL:
      // Added by request of Cristina Alcocer
      // TODO: Check with UX (Alicia) to confirm the look
      case EditorialContentPurpose.INTRODUCTION:
      case EditorialContentPurpose.EXTENDED_CONTENT:
      case EditorialContentPurpose.FREE_TEXT:
      case EditorialContentPurpose.THIRD_PARTY:
      case EditorialContentPurpose.ARTICLE:
      case EditorialContentPurpose.CARDS:
      case EditorialContentPurpose.COUPONS:
      case EditorialContentPurpose.CROSSLINKING:
      case EditorialContentPurpose.DEALS:
      case EditorialContentPurpose.DESTINATION_OVERVIEW:
      case EditorialContentPurpose.FLIGHT_STATISTICS:
      case EditorialContentPurpose.GLOBAL_CAR_LINKS:
      case EditorialContentPurpose.INVENTORY:
      case EditorialContentPurpose.LEGAL:
      case EditorialContentPurpose.POCKET_GUIDE:
      case EditorialContentPurpose.PRACTICAL_ADVICE:
      case EditorialContentPurpose.PROMOTIONAL:
      case EditorialContentPurpose.SECTION_HEADER:
      case EditorialContentPurpose.SEO_LINKS:
      case EditorialContentPurpose.SEO_TITLE:
      case EditorialContentPurpose.SNAPSHOT:
      case EditorialContentPurpose.TROVER_IMAGES:
      case EditorialContentPurpose.WHEN_TO_GO:
      case EditorialContentPurpose.WIZARD_FOOTER:
        component = EditorialViewFactory.get(props);
        break;
      case EditorialContentPurpose.IMAGE_SCAPE:
        component = new ImageEditorial(props);
        break;
      default:
        return new NullContentComponent(props);
    }

    // special cases
    switch (props.model.view) {
      case ModuleViewType.Info:
        return new InfoViewEditorial(props);
      case ModuleViewType.CardWithLink:
        return new CardWithLinkEditorial(props);
      case ModuleViewType.CardWithLinkText:
        return new CardWithLinkTextEditorial(props);
      case ModuleViewType.Snippet:
        return new SnippetEditorial(props);
      case ModuleViewType.AffinityCard:
        return new AffinityCard(props);
      case ModuleViewType.ThemeSelectorCard:
        return new ThemeSelectorCard(props);
      case ModuleViewType.MultipleImages:
        return new MultipleImages(props);
      case ModuleViewType.Grid3:
        return new Grid3(props);
      case ModuleViewType.Grid4:
        return new Grid4(props);
      case ModuleViewType.NativeTileShowcase:
        return new NativeTileShowcaseEditorial(props);
      case ModuleViewType.HalfMedia:
        return new HalfMediaEditorial(props);
      case ModuleViewType.SocialMediaGrid:
        return new SocialMediaGridEditorial(props);
      case ModuleViewType.StandaloneMedia:
        return new StandaloneMediaEditorial(props);
      case ModuleViewType.DestinationDescription:
        return new DestinationDescriptionEditorial(props);
      case ModuleViewType.FullBleedVerticalCard:
        return new FullBleedVerticalCard(props);
      case ModuleViewType.Carousel:
        return new CarouselEditorial(props);
      case ModuleViewType.ConfidenceMessageImage:
        return new ConfidenceMessageImageEditorial(props);
      default:
        return component;
    }
  };
}

function getMainPurpose(contentPurpose?: string): string | null {
  if (!contentPurpose) {
    return null;
  }

  // Remove numbers after purposes. Ex: Editorial 3 becomes Editorial
  return contentPurpose.split(" ")[0];
}

export enum ModuleViewType {
  CardWithLink = "card-with-link",
  Snippet = "snippet",
  Info = "info",
  AffinityCard = "affinity-card",
  ThemeSelectorCard = "theme-selector-card",
  MultipleImages = "multiple-images",
  Grid3 = "grid-3",
  Grid4 = "grid-4",
  NativeTileShowcase = "nts-flex",
  HalfMedia = "half-media",
  OnCanvas = "on-canvas",
  SingleColumnOnCanvas = "single-column-on-canvas",
  OnCanvasBanner = "on-canvas-banner",
  SocialMediaGrid = "social-media-grid",
  StandaloneMedia = "standalone-media",
  DestinationDescription = "destination-description",
  FullBleedVerticalCard = "full-bleed-vertical-card",
  Carousel = "carousel",
  CardWithLinkText = "card-with-link-text",
  ConfidenceMessageImage = "confidence-message-image",
}

// Flex manager sets an optional string contentPurpose for each Editorial.
// IMPORTANT: This was manually added from flex manager
// https://flexmanager.prod.expedia.com/#/flexTemplate/list?containsModule=editorial&pageType=TRAVEL_GUIDE_HOTELS&attributeName=contentPurpose&offset=0&max=20&order=desc&userId=30714061
export enum EditorialContentPurpose {
  THIRD_PARTY = "3rdParty",
  ARTICLE = "Article",
  ARTICLE_1 = "Article 1",
  ARTICLE_2 = "Article 2",
  ARTICLE_3 = "Article 3",
  ARTICLE_4 = "Article 4",
  ARTICLE_5 = "Article 5",
  CARDS = "Cards",
  CONFIDENCE_MESSAGE = "ConfidenceMessage",
  CONFIDENCE_MESSAGE_2 = "ConfidenceMessage 2",
  CONFIDENCE_MESSAGE_3 = "ConfidenceMessage 3",
  CONFIDENCE_MESSAGE_4 = "ConfidenceMessage 4",
  CONFIDENCE_MESSAGE_5 = "ConfidenceMessage 5",
  CONFIDENCE_MESSAGE_6 = "ConfidenceMessage 6",
  COUPONS = "Coupons",
  COUPONS_1 = "Coupons 1",
  COUPONS_2 = "Coupons 2",
  COUPONS_3 = "Coupons 3",
  CROSSLINKING = "Crosslinking",
  CROSSLINKING_1 = "Crosslinking 1",
  CROSSLINKING_2 = "Crosslinking 2",
  CROSSLINKING_3 = "Crosslinking 3",
  CROSSLINKING_4 = "Crosslinking 4",
  CROSSLINKING_5 = "Crosslinking 5",
  CROSSLINKING_6 = "Crosslinking 6",
  CROSSLINKING_7 = "Crosslinking 7",
  CROSSLINKING_8 = "Crosslinking 8",
  CROSSLINKING_9 = "Crosslinking 9",
  CROSSLINKING_10 = "Crosslinking 10",
  DEALS = "Deals",
  DEALS_1 = "Deals 1",
  DEALS_10 = "Deals 10",
  DEALS_11 = "Deals 11",
  DEALS_12 = "Deals 12",
  DEALS_13 = "Deals 13",
  DEALS_14 = "Deals 14",
  DEALS_15 = "Deals 15",
  DEALS_2 = "Deals 2",
  DEALS_3 = "Deals 3",
  DEALS_4 = "Deals 4",
  DEALS_5 = "Deals 5",
  DEALS_6 = "Deals 6",
  DEALS_7 = "Deals 7",
  DEALS_8 = "Deals 8",
  DEALS_9 = "Deals 9",
  DESTINATION_OVERVIEW = "Destination",
  EDITORIAL = "Editorial",
  EXTENDED_CONTENT = "ExtendedContent",
  FLIGHT_STATISTICS = "Flight",
  FREE_TEXT = "FreeText",
  GLOBAL_CAR_LINKS = "GlobalCarLinks",
  HIGHLIGHTS = "Highlights",
  IMAGE_SCAPE = "ImageScape",
  INTRODUCTION = "Introduction",
  INVENTORY = "Inventory",
  LEGAL = "Legal",
  LOB_CROSSLINKING = "LobCrosslinking",
  POCKET_GUIDE = "PocketGuide",
  PRACTICAL_ADVICE = "Practical",
  PROMOTIONAL = "Promotional",
  SECTION_HEADER = "SectionHeader",
  SECTION_HEADER_1 = "SectionHeader 1",
  SECTION_HEADER_2 = "SectionHeader 2",
  SECTION_HEADER_3 = "SectionHeader 3",
  SECTION_HEADER_4 = "SectionHeader 4",
  SEO_LINKS = "SEOLinks",
  SEO_TITLE = "SEOTitle",
  SNAPSHOT = "Snapshot",
  TROVER_IMAGES = "TroverImages",
  WHEN_TO_GO = "When",
  WIZARD_FOOTER = "WizardFooter",
}
