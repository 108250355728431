import * as React from "react";
import {
  UitkLayoutGrid,
  UitkLayoutGridItem,
  UitkLayoutGridColumnRow,
  UitkLayoutGridSpan,
} from "uitk-react-layout-grid";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { UitkText } from "uitk-react-text";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { UitkSpacing } from "uitk-react-spacing";

interface IconsProps {
  icon: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
}

export const Icons: React.FC<IconsProps> = ({ icon, description, title }) => (
  <UitkSpacing padding={{ inline: "six" }}>
    <div>
      <UitkSpacing
        padding={{
          extra_large: { blockstart: "twelve" },
          large: { blockstart: "six" },
          medium: { blockstart: "six" },
          small: { blockstart: "six" },
        }}
      >
        <UitkText align="center">
          <div>{icon}</div>
        </UitkText>
      </UitkSpacing>
      <UitkSpacing padding={{ blockstart: "four" }}>
        <UitkText align="center" size={500} weight="bold">
          <div>{title}</div>
        </UitkText>
      </UitkSpacing>
      <UitkSpacing padding={{ blockstart: "one" }}>
        <UitkText align="center" size={300}>
          <div>{description}</div>
        </UitkText>
      </UitkSpacing>
    </div>
  </UitkSpacing>
);

export const IconSectionEditorial = (props: { items: EditorialModel[] }) => {
  const { items } = props;

  if (items.length !== 10) {
    return null;
  }

  const [
    iconSectionTitle,
    moreRewardsIcon,
    moreRewardsTitle,
    moreRewardsDescription,
    ultimateFlexibilityIcon,
    ultimateFlexibilityTitle,
    ultimateFlexibilityDescription,
    lessHassleIcon,
    lessHassleTitle,
    lessHassleDescription,
  ] = items.map((item) => <DecorateEditorialHtml key={item.id} text={item.text} />);

  const IconColSpan = {
    extra_large: 4 as UitkLayoutGridSpan,
    large: 4 as UitkLayoutGridSpan,
    medium: 4 as UitkLayoutGridSpan,
    small: 1 as UitkLayoutGridSpan,
  };

  const gridColumns = {
    extra_large: 12 as UitkLayoutGridColumnRow,
    large: 12 as UitkLayoutGridColumnRow,
    medium: 12 as UitkLayoutGridColumnRow,
    small: 1 as UitkLayoutGridColumnRow,
  };

  return (
    <UitkSpacing padding={{ block: "twelve" }}>
      <div className="icon-section">
        <UitkText align="center" size={600} weight="bold" spacing="four">
          <div>{iconSectionTitle}</div>
        </UitkText>

        <UitkText spacing="six" />

        <UitkLayoutGrid columns={gridColumns}>
          <UitkLayoutGridItem colSpan={IconColSpan}>
            <UitkLayoutGrid justifyItems="center">
              <UitkLayoutGridItem>
                <Icons icon={moreRewardsIcon} title={moreRewardsTitle} description={moreRewardsDescription} />
              </UitkLayoutGridItem>
            </UitkLayoutGrid>
          </UitkLayoutGridItem>

          <UitkLayoutGridItem colSpan={IconColSpan}>
            <UitkLayoutGrid justifyItems="center">
              <UitkLayoutGridItem>
                <Icons
                  icon={ultimateFlexibilityIcon}
                  title={ultimateFlexibilityTitle}
                  description={ultimateFlexibilityDescription}
                />
              </UitkLayoutGridItem>
            </UitkLayoutGrid>
          </UitkLayoutGridItem>

          <UitkLayoutGridItem colSpan={IconColSpan}>
            <UitkLayoutGrid justifyItems="center">
              <UitkLayoutGridItem>
                <Icons icon={lessHassleIcon} title={lessHassleTitle} description={lessHassleDescription} />
              </UitkLayoutGridItem>
            </UitkLayoutGrid>
          </UitkLayoutGridItem>
        </UitkLayoutGrid>
      </div>
    </UitkSpacing>
  );
};

export default IconSectionEditorial;
