import * as React from "react";
import { CTACarouselRegionContext } from "src/components/flexComponents/CTACarouselRegion/CTACarouselRegion";
import { SimpleContainerContext } from "src/components/flexComponents/SimpleContainer/SimpleContainer";
import { ToggleSingleContainerContext } from "src/components/flexComponents/ToggleContainer/views/ToggleSingleContainer";
import { ResponsiveToggleContainerContext } from "src/components/flexComponents/ResponsiveToggleContainer/ResponsiveToggleContainer";
import { classNames } from "uitk-react-utils";
import { withStores } from "stores";
import { observer } from "mobx-react";
import { RenderableContentCard } from "../editorials/RenderableContentCard";
import { RenderableContentOnCanvas } from "../editorials/RenderableContentOnCanvas";
import { ModuleViewType } from "components/flexComponents/Editorial/factory/EditorialContentFactory";
import { useRenderableContext } from "../editorials/DefaultEditorial";
import { TrackedVisibility } from "src/components/utility/analytics/TrackedVisibility";

/**
 * Editorial Item which will wrap text in a split column for desktop
 * and mobile pages as well as a card.
 *
 *
 */

const getComponentFromView = (view?: string) => {
  switch (view) {
    case ModuleViewType.OnCanvas:
    case ModuleViewType.OnCanvasBanner:
    case ModuleViewType.SingleColumnOnCanvas:
      return RenderableContentOnCanvas;
    default:
      return RenderableContentCard;
  }
};

export const RenderableContentItem: React.FC = withStores("flexViewModel")(
  observer(() => {
    const { title, view, toggle, contentSource, enableContentSource, trackRfrr } = useRenderableContext();

    const isExpandoEnabled = title && (toggle === "collapsed" || toggle === "expanded");
    const [toggleStatus, setToggle] = React.useState(toggle === "collapsed");
    const flipToggleStatus = (): void => {
      setToggle(!toggleStatus);
    };
    const simpleContainerContext = React.useContext(SimpleContainerContext);
    const toggleContainerContext = React.useContext(ToggleSingleContainerContext);
    const responsiveToggleContainerContext = React.useContext(ResponsiveToggleContainerContext);
    const ctaCarouselContext = React.useContext(CTACarouselRegionContext);
    const shouldRenderBorder =
      simpleContainerContext.modulesHaveBorder ||
      toggleContainerContext.modulesHaveBorder ||
      responsiveToggleContainerContext.modulesHaveBorder ||
      ctaCarouselContext.hasBorder;

    const displayEditorialClassNames = classNames("uitk-text uitk-type-300 uitk-type-regular uitk-text-default-theme", {
      [String(contentSource)]: contentSource && enableContentSource,
    });
    const RenderableComponent = getComponentFromView(view);

    const moduleName = title || view || "RenderableContentItem";
    const rfrrId = trackRfrr || moduleName;

    return (
      <TrackedVisibility rfrrId={rfrrId} moduleName={moduleName}>
        <RenderableComponent
          shouldRenderBorder={shouldRenderBorder}
          isExpandoEnabled={isExpandoEnabled}
          flipToggleStatus={flipToggleStatus}
          toggleStatus={toggleStatus}
          displayEditorialClassNames={displayEditorialClassNames}
        />
      </TrackedVisibility>
    );
  })
);
