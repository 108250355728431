import { EditorialFactoryProps } from "components/flexComponents/Editorial/typings";
import * as React from "react";
import EditorialBase from "components/flexComponents/Editorial/factory/EditorialBase";
import { EditorialModel } from "typings/microserviceModels/content-flex-module";
import EditorialItemModel from "components/flexComponents/Editorial/factory/impl/items/EditorialItemModel";
import { UitkCard, UitkCardContentSection } from "uitk-react-cards";
import { UitkHeading, UitkParagraph } from "uitk-react-text";
import {
  PageBackgroundContext,
  BackgroundColorTokens,
} from "src/components/flexComponents/PageLevelProviders/PageBackgroundColor/PageBackgroundContext";
import { UitkLayoutGrid, UitkLayoutGridItem } from "uitk-react-layout-grid";
import { UitkSpacing } from "uitk-react-spacing";

export class AffinityDescription extends EditorialBase {
  /* istanbul ignore next */
  public constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }
  /**
   * An Editorial consists of HTML strings that we parse through with the best of
   * our abilities and display as is on the page. Mainly, we do not control the
   * content of an Editorial, we simply put them in the page.
   */
  public generate() {
    return (
      <div id={this.id} data-fm={this.fmId} data-fm-title-id={this.fmTitleId}>
        {this.editorial.items
          .filter((item: EditorialModel) => item.text && item.title)
          .map((item: EditorialModel) => {
            return this.getItem(new EditorialItemModel(item.id, item.text, item.title, this.editorial.uitkIconName));
          })}
      </div>
    );
  }

  public decorateAffinityDescriptionHtml(text = "") {
    const htmlWithStyle = text.replace(
      /<h3/gi,
      '$& class="uitk-text uitk-type-regular uitk-type-600 uitk-spacing uitk-spacing-margin-blockend-two"'
    );
    return { __html: htmlWithStyle };
  }

  public getItem(model: EditorialItemModel): JSX.Element {
    const backgroundColor = React.useContext(PageBackgroundContext);
    const withBorder = backgroundColor === BackgroundColorTokens.BACKGROUND_WHITE;
    return (
      <UitkSpacing margin={{ block: "three" }}>
        <UitkCard key={`${this.contentPurpose}-${this.keyHelper.next()}`} border={withBorder}>
          <UitkCardContentSection>
            <UitkLayoutGrid columns={1}>
              <UitkLayoutGridItem>
                <div data-fm-content={model.id} id={model.id}>
                  {this.flexContentComponent.pageHeading && (
                    <UitkSpacing margin={{ blockend: "one" }}>
                      <UitkHeading tag="h2" size={2}>
                        {this.flexContentComponent.pageHeading}
                      </UitkHeading>
                    </UitkSpacing>
                  )}
                  {model.title && (
                    <UitkHeading size={6} tag="h2">
                      {model.title}
                    </UitkHeading>
                  )}
                  {model.text && (
                    <UitkSpacing padding={{ blockstart: "two" }}>
                      <UitkParagraph>
                        <div dangerouslySetInnerHTML={this.decorateAffinityDescriptionHtml(model.text)} />
                      </UitkParagraph>
                    </UitkSpacing>
                  )}
                </div>
              </UitkLayoutGridItem>
            </UitkLayoutGrid>
          </UitkCardContentSection>
        </UitkCard>
      </UitkSpacing>
    );
  }
}

export default AffinityDescription;
