import * as React from "react";
import CitiHeaderSection from "./CitiHeaderSection";
import CitiCalculatorContainer from "./CitiCalculatorContainer";
import { EditorialFlexModule } from "src/typings/microserviceModels/content-flex-module";

export const CitiCalculatorSectionEditorial = (props: EditorialFlexModule) => {
  const { title, items = [] } = props;

  return (
    <>
      {title === "Citi_Calculator_Section_Heading" && <CitiHeaderSection items={items} />}
      {title === "Citi_Calculator_Section_Container" && <CitiCalculatorContainer items={items} />}
    </>
  );
};

export default CitiCalculatorSectionEditorial;
