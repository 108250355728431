import * as React from "react";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";
import { UitkExpandoPeek } from "uitk-react-expando";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkHeading } from "uitk-react-text";
import { UitkSpacing } from "uitk-react-spacing";
import { DecorateEditorialHtml } from "components/flexComponents/Editorial/ContentUtilities";

export const RenderAbleContentItemExpando = (contentProps: { id: string; body?: string; title?: string }) => {
  const SMALL_COLLAPSE_HEIGHT = 7;
  const MEDIUM_COLLAPSE_HEIGHT = 15;
  const { formatText } = useLocalization();
  const [isExpandoPeekVisible, setIsExpandoPeekVisible] = React.useState(false);
  const descriptionText = (thresholdHeight: number) =>
    Boolean(contentProps.body?.length) && (
      <UitkExpandoPeek
        lines={thresholdHeight}
        items={thresholdHeight}
        collapseLabel={formatText("read.less.label")}
        expandLabel={formatText("read.more.label")}
        isVisible={isExpandoPeekVisible}
        handleClick={
          /* remove ignore when react-testing-library and jsdom are updated */
          /* istanbul ignore next */ (): void => setIsExpandoPeekVisible((prevIsVisible) => !prevIsVisible)
        }
      >
        <UitkSpacing padding={{ blockstart: "two" }}>
          <DecorateEditorialHtml text={contentProps.body} />
        </UitkSpacing>
      </UitkExpandoPeek>
    );

  const descriptionView = (thresholdHeight: number) => (
    <article>
      <UitkHeading tag="h2" size={4}>
        {contentProps.title}
      </UitkHeading>
      {descriptionText(thresholdHeight)}
    </article>
  );

  return (
    <div id={contentProps.id}>
      <Viewport>
        <ViewSmall>{descriptionView(SMALL_COLLAPSE_HEIGHT)}</ViewSmall>
        <ViewMedium>{descriptionView(MEDIUM_COLLAPSE_HEIGHT)}</ViewMedium>
      </Viewport>
    </div>
  );
};

export default RenderAbleContentItemExpando;
