import { ContentFactoryProps, ContentProps } from "components/flexComponents/Editorial/typings";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { getFmId } from "src/stores/ExperienceTemplateStore";

/**
 * ContentComponent is the base interface for any implementation that wants to show "Content".
 * A "Content" generally consists of HTML strings that we parse through with the best of
 * our abilities and display as is on the page. Mainly, we do not control the
 * content of a Content, we simply put them in the page.
 */
export abstract class ContentComponent {
  public fmId: string;

  public fmTitleId?: number;

  public id: string;

  public title: string;

  public text: string;

  public abstract getId: () => string;

  public abstract shouldRender: () => boolean;

  public readonly flexContentComponent: ContentFactoryProps;

  public keyHelper: ItemKeyHelper;

  protected constructor(module: ContentFactoryProps) {
    const {
      config: { fmTitleId },
      metadata: { id },
    } = module.templateComponent;
    this.id = id;
    this.fmId = getFmId(module.templateComponent);
    this.fmTitleId = fmTitleId ? fmTitleId : undefined;
    this.flexContentComponent = module;
  }

  public abstract generate(props: ContentProps): JSX.Element | null;

  public getEditorialItemClassName: () => string;
}
