import { ContentComponent } from "components/flexComponents/Editorial/factory/ContentComponent";
import { ContentFactoryProps } from "components/flexComponents/Editorial/typings";

export class NullContentComponent extends ContentComponent {
  constructor(ContentFactoryProps: ContentFactoryProps) {
    super(ContentFactoryProps);
  }

  public shouldRender = (): boolean => false;

  /* istanbul ignore next */
  public generate(props: ContentFactoryProps) {
    return null;
  }

  public getId: () => string;
}
