import * as React from "react";
import { UitkCard, UitkCardContentSection, UitkCardLink } from "uitk-react-cards";
import { UitkFigure, UitkFigureAspectRatioType } from "uitk-react-images";
import { UitkScrim, UitkScrimType, UitkScrimTypes } from "uitk-react-scrim";
import { Action } from "components/utility/analytics/FlexAnalyticsUtils";
import { DecorateEditorialHtml } from "components/flexComponents/Editorial/ContentUtilities";
import { ModuleViewType } from "components/flexComponents/Editorial/factory/EditorialContentFactory";
import { EditorialFlexModule } from "typings/microserviceModels/content-flex-module";
import {
  PageBackgroundContext,
  BackgroundColorTokens,
} from "src/components/flexComponents/PageLevelProviders/PageBackgroundColor/PageBackgroundContext";
import { SimpleContainerContext } from "src/components/flexComponents/SimpleContainer/SimpleContainer";
import { CTACarouselRegionContext } from "src/components/flexComponents/CTACarouselRegion/CTACarouselRegion";
import { ToggleSingleContainerContext } from "src/components/flexComponents/ToggleContainer/views/ToggleSingleContainer";
import { ResponsiveToggleContainerContext } from "src/components/flexComponents/ResponsiveToggleContainer/ResponsiveToggleContainer";
import { BlossomImage } from "src/components/shared/BlossomImage/BlossomImage";
import { UitkHeading, UitkSubheading, UitkText } from "uitk-react-text";
import { UitkLayoutGrid, UitkLayoutGridItem } from "uitk-react-layout-grid";
import { UitkLayoutFlex } from "uitk-react-layout-flex";
import { UitkLayoutPosition } from "uitk-react-layout-position";
import { UitkLink } from "uitk-react-link";
import { UitkSpacing, UitkSpacingProps } from "uitk-react-spacing";

export const uitkFigureAspectRatio = (ratio: string) => {
  switch (ratio) {
    case "1-1": {
      return UitkFigureAspectRatioType.R1_1;
    }
    case "3-2": {
      return UitkFigureAspectRatioType.R3_2;
    }
    case "3-4": {
      return UitkFigureAspectRatioType.R3_4;
    }
    case "4-3": {
      return UitkFigureAspectRatioType.R4_3;
    }
    case "21-9": {
      return UitkFigureAspectRatioType.R21_9;
    }
    case "4-1": {
      return UitkFigureAspectRatioType.R4_1;
    }
    default: {
      return UitkFigureAspectRatioType.R16_9;
    }
  }
};

const getCardScrimTypeByView = (view: string) => {
  const withoutScrim = "" as UitkScrimTypes;
  const defaultScrim = UitkScrimType.BOTTOM;

  const scrimTypes: any = {
    [ModuleViewType.AffinityCard]: UitkScrimType.OVERLAY,
    [ModuleViewType.CardWithLinkText]: withoutScrim,
  };
  return scrimTypes[view] ?? defaultScrim;
};

const displayTextSegment = (view: string) => {
  switch (view) {
    case ModuleViewType.AffinityCard:
      return false;
    case ModuleViewType.ThemeSelectorCard:
      return false;
    default:
      return true;
  }
};

const getCardClassByView = (view: string) => {
  switch (view) {
    case ModuleViewType.Snippet:
      return "snippet";
    default:
      return "cardWithLink";
  }
};

const getFigureAspectRatioByView = (view: string, aspectRatio: string) => {
  switch (view) {
    case ModuleViewType.Snippet:
      return undefined;
    default:
      return uitkFigureAspectRatio(aspectRatio);
  }
};

const getHeaderClassByView = (view: string) => {
  if (view === ModuleViewType.AffinityCard) {
    return "affinityCardHeading";
  }

  return "";
};

export const EditorialWithLink = (props: { model: EditorialFlexModule }) => {
  const { model } = props;
  const moduleName = "editorial";
  const cardLabel = model.items[0].title || model.title;
  const cardBody = model.items[0].text ?? "";
  const cardSubLabel = model.items[0].subtitle || model.subtitle;
  const cardName = cardLabel && cardLabel.replace(" ", "_");
  const rfrr = cardName ? `${moduleName}.${cardName}.${Action.CLICK}` : null;
  const cardClassName = getHeaderClassByView(model.view);
  const dynamicClickUrl = model.items[0].media ? model.items[0].media[0].clickUrl : "";
  const url = dynamicClickUrl?.length > 0 ? dynamicClickUrl : model.cardLinkUrl;
  const { excludeRfrr } = model;
  const cardLink = excludeRfrr || !rfrr ? url : `${url}?rfrr=${rfrr}`;
  const cardLinkTarget = model.openLinkInNewWindow ? "_blank" : "_self";

  const viewClassname = getCardClassByView(model.view);
  const backgroundColor = React.useContext(PageBackgroundContext);
  const withBorder = model.boxType !== "no-border" && backgroundColor === BackgroundColorTokens.BACKGROUND_WHITE;
  const simpleContainerContext = React.useContext(SimpleContainerContext);
  const toggleContainerContext = React.useContext(ToggleSingleContainerContext);
  const responsiveToggleContainerContext = React.useContext(ResponsiveToggleContainerContext);
  const ctaCarouselContext = React.useContext(CTACarouselRegionContext);
  const border =
    withBorder ||
    simpleContainerContext.modulesHaveBorder ||
    toggleContainerContext.modulesHaveBorder ||
    responsiveToggleContainerContext.modulesHaveBorder ||
    ctaCarouselContext.hasBorder;
  /**
   * This is a temporary work around due to limitations of the editorial model that will be addressed in the future.
   * Parsing data from contributed HTML content should not be considered an accepted approach to be repeated elsewhere
   */
  const [, title] = /<h3 [^>]+>(.*?)<\/h3>/g.exec(cardBody) || [];
  const [, titleSpan] = /<span [^>]+>(.*?)<\/span>/g.exec(title) || [];
  const text = titleSpan ?? title;
  const selectedMedia = model.items?.[0]?.media?.[0];
  const rel = model.openLinkInNewWindow ? "noopener noreferrer" : undefined;

  return (
    <UitkCard border={border} className={viewClassname}>
      <UitkLayoutGrid className={viewClassname} columns={4}>
        <EditorialImageFigure
          selectedMedia={selectedMedia}
          label={cardLabel}
          subLabel={cardSubLabel}
          className={cardClassName}
          model={model}
          viewClassname={viewClassname}
        />
        <EditorialContentSection body={cardBody} model={model} viewClassname={viewClassname} />
      </UitkLayoutGrid>
      <UitkCardLink>
        <a href={cardLink} data-testid="editorial-with-link-anchor" rel={rel} target={cardLinkTarget}>
          {text || cardLabel || model.title || selectedMedia?.mediaAlt || moduleName}
        </a>
      </UitkCardLink>
    </UitkCard>
  );
};

interface SelectedMedia {
  mediaUrl: string;
  mediaAlt: string;
}

interface EditorialImageFigureProps {
  selectedMedia?: SelectedMedia;
  model: EditorialFlexModule;
  viewClassname: string;
  label: string;
  subLabel: string;
  className: string;
}

const defaultImageFigureTextAlignment = {
  layoutPosition: {
    bottom: "three",
    left: "three",
  },
  gridPosition: "start",
};

const centeredImageFigureTextAlignment = {
  layoutPosition: {
    top: "zero",
    right: "zero",
    bottom: "zero",
    left: "zero",
  },
  gridPosition: "center",
};

const getImageFigureHeadingTextComponent = (view: string) => {
  const imageFigureHeadingtextSizes: any = {
    [ModuleViewType.FullBleedVerticalCard]: UitkSubheading,
  };
  return imageFigureHeadingtextSizes[view] ?? UitkHeading;
};

const getImageFigureTextAlignment = (view: string) => {
  const imageFigureTextAlignments: any = {
    [ModuleViewType.AffinityCard]: centeredImageFigureTextAlignment,
  };
  return imageFigureTextAlignments[view] ?? defaultImageFigureTextAlignment;
};

const imageFigureTextAlignment = "center";
const imageFigureHeadingAlignment = "center";

const EditorialImageFigure: React.FC<EditorialImageFigureProps> = ({
  selectedMedia,
  label,
  subLabel,
  className,
  model,
  viewClassname,
}) => {
  const HeadingComponent = getImageFigureHeadingTextComponent(model.view);
  const { layoutPosition, gridPosition } = getImageFigureTextAlignment(model.view);
  const scrimType = getCardScrimTypeByView(model.view);
  const aspectRatio = getFigureAspectRatioByView(model.view, model.imageAspectRatio);

  return (
    <UitkLayoutGridItem colSpan={model.view === ModuleViewType.Snippet ? 1 : "all"} className={viewClassname}>
      <UitkLayoutFlex id="media-content">
        <UitkFigure ratio={aspectRatio}>
          {selectedMedia && (
            <BlossomImage
              src={selectedMedia.mediaUrl}
              alt={selectedMedia.mediaAlt}
              imageRatio={model.imageAspectRatio}
              widthSmall={model.minWidthSmall}
              widthMedium={model.minWidthMedium}
              widthLarge={model.minWidthLarge}
              quality={model.quality}
            />
          )}
          <UitkScrim type={scrimType}>
            <UitkLayoutPosition type="absolute" position={layoutPosition} cloneElement>
              <UitkLayoutGrid alignItems={gridPosition} justifyItems={gridPosition}>
                {label && (
                  <HeadingComponent align={imageFigureHeadingAlignment} className={className}>
                    {label}
                  </HeadingComponent>
                )}
                {subLabel && (
                  <UitkText align={imageFigureTextAlignment} size={300}>
                    {subLabel}
                  </UitkText>
                )}
              </UitkLayoutGrid>
            </UitkLayoutPosition>
          </UitkScrim>
        </UitkFigure>
      </UitkLayoutFlex>
    </UitkLayoutGridItem>
  );
};

interface EditorialContentSectionProps {
  body: string;
  model: EditorialFlexModule;
  viewClassname: string;
}

const defaultContentTextSize = 200;

const getContentTextSizeByView = (view: string) => {
  const contentTextSizes: any = {
    [ModuleViewType.CardWithLinkText]: 300,
  };
  return contentTextSizes[view] ?? defaultContentTextSize;
};

const EditorialContentSection: React.FC<EditorialContentSectionProps> = ({ body, model, viewClassname }) => {
  if (!displayTextSegment(model.view) || !model.items[0].text) {
    return null;
  }

  const textContentSize = getContentTextSizeByView(model.view);
  const mediaContent = model.items?.[0]?.media?.[0];
  const mediaUrl = mediaContent?.clickUrl;
  const mediaCaption = mediaContent?.mediaCaption;
  const isCardLinkWithTextView = model.view === ModuleViewType.CardWithLinkText;
  const paddingContent: UitkSpacingProps["padding"] = isCardLinkWithTextView ? "six" : "three";

  return (
    <UitkLayoutGridItem colSpan={model.view === ModuleViewType.Snippet ? 3 : "all"} className={viewClassname}>
      <UitkLayoutFlex id="card-content">
        <UitkSpacing padding={paddingContent}>
          <UitkCardContentSection>
            <UitkText size={textContentSize} align="left">
              <DecorateEditorialHtml text={body} />
            </UitkText>
            {isCardLinkWithTextView && mediaCaption && (
              <UitkSpacing margin={{ blockstart: "four" }} padding="unset">
                <UitkLink>
                  <a href={mediaUrl}>{mediaCaption}</a>
                </UitkLink>
              </UitkSpacing>
            )}
          </UitkCardContentSection>
        </UitkSpacing>
      </UitkLayoutFlex>
    </UitkLayoutGridItem>
  );
};

export default EditorialWithLink;
