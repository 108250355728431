import { EditorialFactoryProps } from "components/flexComponents/Editorial/typings";
import CardWithLinkEditorial from "components/flexComponents/Editorial/factory/impl/editorials/CardWithLinkEditorial";

export class CardWithLinkTextEditorial extends CardWithLinkEditorial {
  /* istanbul ignore next */
  constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }

  public getEditorialItemClassName = (): string =>
    "cardWithLinkText uitk-spacing uitk-spacing-padding-blockstart-three";
}

export default CardWithLinkTextEditorial;
