import * as React from "react";
import { UitkPrimaryButton } from "uitk-react-button";
import { useLocation } from "react-router";
import { UitkSticky } from "uitk-react-sticky";
import { UitkText } from "uitk-react-text";
import {
  UitkLayoutGrid,
  UitkLayoutGridItem,
  UitkLayoutConditionalGridSpan,
  UitkLayoutGridColumnRow,
} from "uitk-react-layout-grid";
import { cardLink } from "src/stores/wizard/config/overrideConfigBySiteId/siteIds/siteId1";
import { ViewExtraLarge, ViewLarge, Viewport } from "@shared-ui/viewport-context";
import { DecorateEditorialHtml, returnCitiCode } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkSpacing } from "uitk-react-spacing";

export const CitiDesktopStickyBar = (props: { items: EditorialModel[] }) => {
  const { formatText } = useLocalization();
  const { search: searchUrl } = useLocation();
  const { items } = props;
  const imageColSpan: UitkLayoutConditionalGridSpan = { small: 2, medium: 2, large: 2, extra_large: 2 };
  const textColSpan: UitkLayoutConditionalGridSpan = { small: 4, medium: 4, large: 4, extra_large: 4 };
  const separatorColSpan: UitkLayoutConditionalGridSpan = { small: 1, medium: 1, large: 1, extra_large: 1 };
  const buttonColSpan: UitkLayoutConditionalGridSpan = { small: 3, medium: 3, large: 3, extra_large: 3 };

  const gridColumns = {
    extra_large: 12 as UitkLayoutGridColumnRow,
    large: 12 as UitkLayoutGridColumnRow,
    medium: 12 as UitkLayoutGridColumnRow,
    small: 12 as UitkLayoutGridColumnRow,
  };

  if (items.length !== 5) {
    return null;
  }
  const [feeText, separator, feeImage, noFeeImage, noFeeText] = items.map((item) => (
    <DecorateEditorialHtml key={item.id} text={item.text} />
  ));
  const applyNowText = formatText("grid3.citiDesktopStickybar.applyNow.text");
  const citiCode = returnCitiCode(searchUrl);
  const feeCardApplyNowLink = citiCode ? cardLink.feeCardLink + `&citiCode=${citiCode}` : cardLink.feeCardLink;
  const noFeeCardApplyNowLink = citiCode ? cardLink.noFeeCardLink + `&citiCode=${citiCode}` : cardLink.noFeeCardLink;

  return (
    <Viewport>
      <ViewExtraLarge>
        <section>
          <UitkSticky hidden zIndex={1}>
            <div className="uitk-button-old-raised">
              <UitkLayoutGrid columns={{ auto_fill: "sixteen" }}>
                <UitkLayoutGridItem colSpan={imageColSpan}>
                  <div>
                    <UitkSpacing padding={{ blockstart: "four", blockend: "two" }}>
                      <UitkText align="right">
                        <div>{feeImage}</div>
                      </UitkText>
                    </UitkSpacing>
                  </div>
                </UitkLayoutGridItem>
                <UitkLayoutGridItem colSpan={textColSpan}>
                  <div>
                    <UitkText align="left" size={400} weight="bold">
                      <UitkSpacing padding={{ blockstart: "six", inlinestart: "three" }}>
                        <UitkText align="left">
                          <div>{feeText}</div>
                        </UitkText>
                      </UitkSpacing>
                    </UitkText>
                  </div>
                </UitkLayoutGridItem>
                <UitkLayoutGridItem colSpan={imageColSpan}>
                  <UitkSpacing padding={{ blockstart: "six" }}>
                    <UitkText align="center">
                      <UitkPrimaryButton type="button" href={feeCardApplyNowLink}>
                        {applyNowText}{" "}
                      </UitkPrimaryButton>
                    </UitkText>
                  </UitkSpacing>
                </UitkLayoutGridItem>
                <UitkLayoutGridItem colSpan={separatorColSpan}>
                  <div>
                    <UitkSpacing margin={{ inlinestart: "twelve" }}>
                      <UitkText align="center">
                        <div>{separator}</div>
                      </UitkText>
                    </UitkSpacing>
                  </div>
                </UitkLayoutGridItem>
                <UitkLayoutGridItem colSpan={imageColSpan}>
                  <div>
                    <UitkSpacing padding={{ blockstart: "four", blockend: "two" }}>
                      <UitkText align="right">
                        <div>{noFeeImage}</div>
                      </UitkText>
                    </UitkSpacing>
                  </div>
                </UitkLayoutGridItem>
                <UitkLayoutGridItem colSpan={textColSpan}>
                  <div>
                    <UitkText align="left" size={400} weight="bold">
                      <UitkSpacing padding={{ blockstart: "six", inlinestart: "three" }}>
                        <UitkText align="left">
                          <div>{noFeeText}</div>
                        </UitkText>
                      </UitkSpacing>
                    </UitkText>
                  </div>
                </UitkLayoutGridItem>
                <UitkLayoutGridItem colSpan={buttonColSpan}>
                  <UitkSpacing padding={{ blockstart: "six" }}>
                    <UitkText align="center">
                      <UitkPrimaryButton type="button" href={noFeeCardApplyNowLink}>
                        {applyNowText}
                      </UitkPrimaryButton>
                    </UitkText>
                  </UitkSpacing>
                </UitkLayoutGridItem>
              </UitkLayoutGrid>
            </div>
          </UitkSticky>
        </section>
      </ViewExtraLarge>

      <ViewLarge>
        <section>
          <UitkSticky hidden zIndex={1}>
            <div className="background-color-white withBorder uitk-button-old-raised">
              <UitkLayoutGrid columns={gridColumns}>
                <UitkLayoutGridItem colSpan={separatorColSpan}>
                  <div>
                    <UitkSpacing padding={{ blockstart: "four", blockend: "two" }}>
                      <UitkText align="right">
                        <div>{feeImage}</div>
                      </UitkText>
                    </UitkSpacing>
                  </div>
                </UitkLayoutGridItem>
                <UitkLayoutGridItem colSpan={buttonColSpan}>
                  <div>
                    <UitkText align="left" size={400} weight="bold">
                      <UitkSpacing padding={{ blockstart: "six", inlinestart: "three" }}>
                        <UitkText align="left">
                          <div>{feeText}</div>
                        </UitkText>
                      </UitkSpacing>
                    </UitkText>
                  </div>
                </UitkLayoutGridItem>
                <UitkLayoutGridItem colSpan={imageColSpan}>
                  <UitkSpacing padding={{ blockstart: "six" }}>
                    <UitkText align="center">
                      <UitkPrimaryButton type="button" href={feeCardApplyNowLink}>
                        {applyNowText}
                      </UitkPrimaryButton>
                    </UitkText>
                  </UitkSpacing>
                </UitkLayoutGridItem>
                <UitkLayoutGridItem colSpan={separatorColSpan}>
                  <UitkSpacing padding={{ inlinestart: "four" }}>
                    <UitkText align="center">
                      <div>{separator}</div>
                    </UitkText>
                  </UitkSpacing>
                </UitkLayoutGridItem>
                <UitkLayoutGridItem colSpan={separatorColSpan}>
                  <div>
                    <UitkSpacing padding={{ blockstart: "four", blockend: "two" }}>
                      <UitkText align="right">
                        <div>{noFeeImage}</div>
                      </UitkText>
                    </UitkSpacing>
                  </div>
                </UitkLayoutGridItem>
                <UitkLayoutGridItem colSpan={imageColSpan}>
                  <div>
                    <UitkText align="left" size={400} weight="bold">
                      <UitkSpacing padding={{ blockstart: "six", inlinestart: "three" }}>
                        <UitkText align="left">
                          <div>{noFeeText}</div>
                        </UitkText>
                      </UitkSpacing>
                    </UitkText>
                  </div>
                </UitkLayoutGridItem>
                <UitkLayoutGridItem colSpan={imageColSpan}>
                  <UitkSpacing padding={{ blockstart: "six" }}>
                    <UitkText align="center">
                      <UitkPrimaryButton type="button" href={noFeeCardApplyNowLink}>
                        {applyNowText}
                      </UitkPrimaryButton>
                    </UitkText>
                  </UitkSpacing>
                </UitkLayoutGridItem>
              </UitkLayoutGrid>
            </div>
          </UitkSticky>
        </section>
      </ViewLarge>
    </Viewport>
  );
};

export default CitiDesktopStickyBar;
