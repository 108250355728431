import {
  UitkLayoutGrid,
  UitkLayoutGridItem,
  UitkLayoutGridColumnRow,
  UitkLayoutGridSpan,
} from "uitk-react-layout-grid";
import { UitkText } from "uitk-react-text";
import * as React from "react";
import { UitkSpacing } from "uitk-react-spacing";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";

export const CitiTripleDipIconSectionWithoutAdd = (props: { items: EditorialModel[] }) => {
  const { items } = props;
  if (!items || items.length !== 3) {
    return null;
  }

  const IconColSpan = {
    small: 1 as UitkLayoutGridSpan,
    medium: 2 as UitkLayoutGridSpan,
    large: 2 as UitkLayoutGridSpan,
    extra_large: 2 as UitkLayoutGridSpan,
  };

  const gridColumns = {
    small: 1 as UitkLayoutGridColumnRow,
    medium: 2 as UitkLayoutGridColumnRow,
    large: 2 as UitkLayoutGridColumnRow,
    extra_large: 2 as UitkLayoutGridColumnRow,
  };

  return (
    <UitkLayoutGrid columns={gridColumns} space="six">
      <UitkLayoutGridItem colSpan={IconColSpan} rowSpan={3}>
        <UitkSpacing padding={{ inlinestart: "four" }}>
          <UitkLayoutGrid space="one">
            <div>
              <UitkText align="center">
                <UitkSpacing padding={{ blockend: "three" }}>
                  <DecorateEditorialHtml text={items[0].text} />
                </UitkSpacing>
              </UitkText>
              <UitkText align="center" size={500} weight="bold">
                <UitkSpacing padding={{ block: "half" }}>
                  <DecorateEditorialHtml text={items[1].text} />
                </UitkSpacing>
              </UitkText>
              <UitkText align="center" size={300}>
                <UitkSpacing padding={{ block: "half" }}>
                  <DecorateEditorialHtml text={items[2].text} />
                </UitkSpacing>
              </UitkText>
            </div>
          </UitkLayoutGrid>
        </UitkSpacing>
      </UitkLayoutGridItem>
    </UitkLayoutGrid>
  );
};
export default CitiTripleDipIconSectionWithoutAdd;
