import { EditorialFactoryProps } from "../typings";
import DefaultEditorial from "./impl/editorials/DefaultEditorial";
import AffinityDescription from "./impl/editorials/AffinityDescription";

enum EditorialView {
  AffinityDescription = "affinity-description",
}

export class EditorialViewFactory {
  public static get = (props: EditorialFactoryProps) => {
    switch (props.model.view) {
      case EditorialView.AffinityDescription:
        return new AffinityDescription(props);
      default:
        return new DefaultEditorial(props);
    }
  };
}
