import * as React from "react";
import EditorialBase from "../../EditorialBase";
import { ContentProps, EditorialFactoryProps } from "components/flexComponents/Editorial/typings";
import IconSectionEditorial from "./IconSectionEditorial";
import CardComparisonSection from "./CardComparisonSection";
import CitiTripleDipSectionEditorial from "./Citi/CititripledipSectionEditorial";
import CitiDesktopStickyBar from "./Citi/CitiDesktopStickybar";
import CitifaqSectionEditorial from "./Citi/CitiFaqSectionEditorial";
import CitiRewardsWithoutCompSectionEditorial from "./Citi/CitiRewardsWithoutCompSectionEditorial";
import CitiCalculatorSectionEditorial from "./Citi/CitiCalculatorSectionEditorial";
import HeroImageEditorial from "./Citi/HeroImageEditorial";
import CitiCardModalDialog from "components/flexComponents/Editorial/factory/impl/editorials/Citi/CitiCardModalDialog";
import CitiContent from "./Citi/CitiContent";
import CitiLearnMoreButton from "./Citi/CitiLearnMoreButton";

export class Grid3 extends EditorialBase {
  constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }

  public generate(props: ContentProps) {
    const { context } = props;
    const { title = "", items } = this.editorial;

    enum CitiLandingPageSections {
      STICKY_BAR_SECTION = "Sticky_bar",
      RWC_SECTION = "Citi_Rwc_Section",
      ICON_SECTION = "icon_section",
      CARD_COMPARISON_SECTION = "Citi_Card_comparision",
      CALCULATOR_SECTION = "Citi_Calculator_Section",
      HERO_IMAGE_SECTION = "hero_image",
      CARD_MODAL_DIALOG_SECTION = "Card_modal_dialog",
      CONTENT_SECTION = "Citi_Content",
      LEARN_MORE_SECTION = "citi_learn_more",
      TRIPLE_DIP_SECTION = "triple_dip_section",
      FAQ_SECTION = "Citi_Faq_Section",
    }

    const cardComparisonClassName = "citi-card-comparison";

    return (
      <>
        {title.includes(CitiLandingPageSections.TRIPLE_DIP_SECTION) && (
          <CitiTripleDipSectionEditorial {...this.editorial} />
        )}

        {title.includes(CitiLandingPageSections.STICKY_BAR_SECTION) && <CitiDesktopStickyBar items={items} />}

        {title.includes(CitiLandingPageSections.FAQ_SECTION) && <CitifaqSectionEditorial {...this.editorial} />}

        {title.includes(CitiLandingPageSections.RWC_SECTION) && (
          <CitiRewardsWithoutCompSectionEditorial model={this.editorial} context={context} />
        )}

        {title.includes(CitiLandingPageSections.ICON_SECTION) && <IconSectionEditorial items={items} />}

        {title.includes(CitiLandingPageSections.CARD_COMPARISON_SECTION) && (
          <CardComparisonSection model={this.editorial} cardComparisonClassName={cardComparisonClassName} />
        )}

        {title.includes(CitiLandingPageSections.CALCULATOR_SECTION) && (
          <CitiCalculatorSectionEditorial {...this.editorial} />
        )}

        {title.includes(CitiLandingPageSections.HERO_IMAGE_SECTION) && <HeroImageEditorial items={items} />}

        {title.includes(CitiLandingPageSections.CARD_MODAL_DIALOG_SECTION) && <CitiCardModalDialog items={items} />}

        {title.includes(CitiLandingPageSections.CONTENT_SECTION) && <CitiContent items={items} />}

        {title.includes(CitiLandingPageSections.LEARN_MORE_SECTION) && (
          <CitiLearnMoreButton items={items} cardComparisonClassName={cardComparisonClassName} />
        )}
      </>
    );
  }
}

export default Grid3;
