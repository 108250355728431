import { EditorialFactoryProps } from "components/flexComponents/Editorial/typings";
import CardWithLinkEditorial from "components/flexComponents/Editorial/factory/impl/editorials/CardWithLinkEditorial";

export class Affinity extends CardWithLinkEditorial {
  /* istanbul ignore next */
  constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }

  public getEditorialItemClassName = (): string => "affinityCard all-y-gutter-three";
}

export default Affinity;
