import * as React from "react";
import { useDialog } from "@shared-ui/dialog-context";
import { ContentProps } from "components/flexComponents/Editorial/typings";
import { UitkIcon, UitkIconSize } from "uitk-react-icons";
import { UitkCard, UitkCardContentSection, UitkCardActionArea } from "uitk-react-cards";
import { UitkTertiaryButton, UitkButtonSize } from "uitk-react-button";
import { EditorialModel } from "typings/microserviceModels/content-flex-module";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkHeading, UitkText } from "uitk-react-text";
import { UitkLayoutGrid, UitkLayoutGridItem } from "uitk-react-layout-grid";
import { UitkSpacing } from "uitk-react-spacing";
import { UitkSheetTransition } from "uitk-react-sheet";

export const EditorialInfoView = ({ items, props }: { items: EditorialModel[]; props: ContentProps }) => {
  const moreInfoRef = React.createRef<HTMLButtonElement>();
  const [isDialogOpen, dialogActions, EditorialInfoDialogComponent] = useDialog("more-info");

  /* istanbul ignore next */
  const closeDialog = () => {
    dialogActions.closeDialog();

    if (moreInfoRef && moreInfoRef.current && typeof moreInfoRef.current.focus === "function") {
      moreInfoRef.current.focus();
    }
  };

  const { formatText } = useLocalization();

  return (
    <div data-fm-content={items[0].id}>
      {items[0].title && (
        <UitkHeading tag="h2" size={4}>
          {items[0].title}
        </UitkHeading>
      )}
      {items[0].text && (
        <UitkSpacing padding={{ blockstart: "two" }}>
          <div className="infoMessage" onClick={dialogActions.openDialog as any}>
            <UitkText size={300}>
              <DecorateEditorialHtml text={items[0].text} />
              <UitkIcon name="info_outline" size={UitkIconSize.SMALL} />
            </UitkText>
          </div>
        </UitkSpacing>
      )}
      <UitkSheetTransition isVisible={isDialogOpen}>
        <EditorialInfoDialogComponent>
          <InfoDialog
            infoText={items[1].text}
            closeDialog={closeDialog}
            buttonText={formatText("editorial.moreInfo.dismissButton.label")}
          />
        </EditorialInfoDialogComponent>
      </UitkSheetTransition>
    </div>
  );
};

const InfoDialog = ({
  infoText,
  closeDialog,
  buttonText,
}: {
  infoText?: string;
  closeDialog: () => void;
  buttonText: string;
}) => {
  const { useRef, useEffect } = React;
  const dialogRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (dialogRef && dialogRef.current) {
      dialogRef.current.focus();
    }
  }, []);

  return (
    <UitkCard className="editorialInfoDialog">
      <UitkCardContentSection>
        <UitkLayoutGrid columns={1}>
          <UitkLayoutGridItem>
            <UitkText size={300}>{infoText && <DecorateEditorialHtml text={infoText} />}</UitkText>
          </UitkLayoutGridItem>
        </UitkLayoutGrid>
        <UitkCardActionArea>
          <UitkTertiaryButton onClick={closeDialog} size={UitkButtonSize.LARGE} isFullWidth domRef={dialogRef}>
            {buttonText}
          </UitkTertiaryButton>
        </UitkCardActionArea>
      </UitkCardContentSection>
    </UitkCard>
  );
};
