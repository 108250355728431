import * as React from "react";
import { EditorialFlexModule } from "../../../../../../typings/microserviceModels/content-flex-module";
import CardComparisonSectionEditorial from "./CardComparisonSectionEditorial";
import CardComparisonImageSectionEditorial from "./CardComparisonImageSectionEditorial";
import CardComparisonFeatureSectionEditorial from "./CardComparisonFeatureSectionEditorial";
import CardComparisonBenefitsSectionEditorial from "./CardComparisonBenefitsSectionEditorial";
import CardComparisonBenefitsTitleEditorial from "./CardComparisonBenefitsTitleEditorial";
import CardComparisonBenefitsEditorial from "./CardComparisonBenefitsEditorial";
import CardComparisonNoFeeBenefitsEditorial from "./CardComparisonNoFeeBenefitsEditorial";
import CardComparisonAddedBenefitsSectionEditorial from "./CardComparisonAddedBenefitsSectionEditorial";
import CardComparisonAddedBenefitsEditorial from "./CardComparisonAddedBenefitsEditorial";
import CardComparisonNoFeeAddedBenefitsEditorial from "./CardComparisonNoFeeAddedBenefitsEditorial";
import CardComparisonMobileApplyNowEditorial from "./CardComparisonMobileApplyNowEditorial";
import CitiCardTierText from "./Citi/CitiCardTierText";

export const CardComparisonSection = (props: { model: EditorialFlexModule; cardComparisonClassName: string }) => {
  const { model, cardComparisonClassName } = props;
  const { title, items } = model;

  return (
    <>
      {title.includes("fee_title") && (
        <CardComparisonSectionEditorial items={items} cardComparisonClassName={cardComparisonClassName} />
      )}
      {title.includes("image_section") && <CardComparisonImageSectionEditorial items={items} />}
      {title.includes("image_tier") && <CitiCardTierText items={items} />}
      {title.includes("grid_section") && <CardComparisonFeatureSectionEditorial items={items} />}
      {title.includes("benefits_title") && <CardComparisonBenefitsSectionEditorial items={items} />}
      {title.includes("benefits_heading") && <CardComparisonBenefitsTitleEditorial items={items} />}
      {title.includes("fee_benefits") && <CardComparisonBenefitsEditorial items={items} />}
      {title.includes("nofees_benefits") && <CardComparisonNoFeeBenefitsEditorial items={items} />}
      {title.includes("added_benefitss_heading") && <CardComparisonAddedBenefitsSectionEditorial items={items} />}
      {title.includes("nofee_added_benefits") && <CardComparisonNoFeeAddedBenefitsEditorial items={items} />}
      {title.includes("addeds_benefits") && <CardComparisonAddedBenefitsEditorial items={items} />}
      {title.includes("mobile_applynow") && <CardComparisonMobileApplyNowEditorial items={items} />}
    </>
  );
};

export default CardComparisonSection;
