import { ContentProps, EditorialFactoryProps } from "components/flexComponents/Editorial/typings";
import * as React from "react";
import { EditorialInfoView } from "components/flexComponents/Editorial/factory/impl/items/EditorialInfoView";
import EditorialBase from "components/flexComponents/Editorial/factory/EditorialBase";

export class InfoViewEditorial extends EditorialBase {
  /* istanbul ignore next */
  constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }

  public generate(props: ContentProps) {
    const classNameAttr = `${this.getEditorialItemClassName()}`;

    if (this.editorial.items.length !== 2) {
      return null;
    }

    return (
      <div className={classNameAttr} id={this.id} data-fm={this.fmId} data-fm-title-id={this.fmTitleId}>
        <EditorialInfoView items={this.editorial.items} props={props} />
      </div>
    );
  }
}

export default InfoViewEditorial;
